import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import config from "../functions/config";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function Notification() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const navigate = useNavigate();

  const [notification, setNotification] = useState([]);
  const [job, setJob] = useState([]);
  const [jobapplication, setJobapplication] = useState([]);

  const [user1, setUser1] = useState([]);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          `${config.baseUrl}api/v1/ex1/notifications`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Token ${token}`,
            },
          }
        );

        const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });

        if (response.data.status === 200) {
          console.log(response);
          setNotification(response.data.data);
        } else {
          console.log("error 1");
        }

        if (res1.data.status === 200) {
          console.log(res1);
          setJob(res1.data.data);
        } else {
          console.log("error 2");
        }

        if (res2.data.status === 200) {
          console.log(res2);
          setJobapplication(res2.data.data);
          setUser1(res2.data.user_id);
        } else {
          console.log("error 3");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchData();
  }, [baseUrl, token]);

  const handleClick = async (id) => {
    const data = {
      is_read: true,
    };
    try {
      const res3 = await axios.put(
        `${config.baseUrl}api/v1/ex1/notification/update/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res3.data.status === 200) {
        console.log(res3);
      } else {
        console.log("error 4");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    // <div>
    //   <div className="container mx-auto">
    //     <h1 className="text-center">Notifications</h1>
    //     <div className="flex flex-wrap">
    //       <div className="w-1/2 bg-white border border-gray-500 ">
    //         <h1 className="text-center font-bold">Unread</h1>

    //         {notification.map((item) => {
    //           return (

    //             <div className="bg-slate-400 border border-violet-700 mb-2">

    //               <h3 className="">
    //               {job.find((itm) => itm.id === item.job).title} {""} {item.description}

    //             </h3>

    //             </div>

    //           );
    //         })}

    //       </div>

    //       <div className="w-1/2 bg-sky-400 ">Read</div>
    //     </div>
    //   </div>
    // </div>

    // <div>
    //   <div className="container mx-auto">
    //     <h1 className="text-center">Notifications</h1>
    //     <div className="flex flex-wrap">
    //       <div className="w-1/2 bg-white border border-gray-500 ">
    //         <h1 className="text-center font-bold">Unread</h1>

    //         {notification.filter(
    //           (item) => item.user === user1 && item.is_read === false
    //         ).length === 0 ? (
    //           <li className="w-full text-center m-auto font-medium text-violet-800">
    //             No Notification
    //           </li>
    //         ) : (
    //           notification
    //             .filter((item) => item.user === user1 && item.is_read === false)
    //             .map((item) => {
    //               return (
    //                 <div
    //                   className="bg-white mb-2 shadow-xl h-10 border border-orange-500"
    //                   onClick={() => {
    //                     handleClick(item.id);
    //                     item.description === "Job Added to the portal"
    //                       ? navigate("/jobscopy")
    //                       : navigate("/jobuserapply");
    //                   }}
    //                 >
    //                   <h3 className="">
    //                     {job.find((itm) => itm.id === item.job).title} {""}{" "}
    //                     {item.description}
    //                   </h3>
    //                 </div>
    //               );
    //             })
    //         )}
    //       </div>

    //       <div className="w-1/2 border border-yellow-400">
    //         <h1 className="text-center font-bold">read</h1>

    //         {notification.filter(
    //           (item) => item.user === user1 && item.is_read === true
    //         ).length === 0 ? (
    //           <li className="w-full text-center mt-11 font-medium text-violet-800">
    //             No Unread Notification
    //           </li>
    //         ) : (
    //           notification
    //             .filter((item) => item.user === user1 && item.is_read === true)
    //             .map((item) => {
    //               return (
    //                 <div
    //                   className="bg-white mb-2 shadow-xl h-10 border border-orange-500"
    //                   onClick={() => {
    //                     item.description === "Job Added to the portal"
    //                       ? navigate("/jobscopy")
    //                       : navigate("/jobuserapply");
    //                   }}
    //                 >
    //                   <h3 className="">
    //                     {job.find((itm) => itm.id === item.job).title} {""}{" "}
    //                     {item.description}
    //                   </h3>
    //                 </div>
    //               );
    //             })
    //         )}
    //       </div>
    //     </div>
    //   </div>
    // </div>

    // <div>
    //   <div className="">
    //     <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
    //       <button
    //         onClick={(e) => {
    //           e.preventDefault();
    //           toggleMenu();
    //         }}
    //       >
    //         <svg
    //           xmlns="http://www.w3.org/2000/svg"
    //           fill="none"
    //           viewBox="0 0 24 24"
    //           stroke-width="1.5"
    //           stroke="currentColor"
    //           class="size-6 text-white"
    //         >
    //           <path
    //             stroke-linecap="round"
    //             stroke-linejoin="round"
    //             d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
    //           />
    //         </svg>
    //       </button>
    //       <h5 className="text-3xl font-bold text-white">Careerhub</h5>
    //     </div>

    //     <div
    //       className={`md:hidden ${
    //         isMenuOpen ? "translate-x-0" : "-translate-x-full"
    //       } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-60 bg-violet-600`}
    //     >
    //       <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
    //         <li>
    //           <Link to="/userprofile">Profile</Link>
    //         </li>
    //         <li>
    //           <Link to="/jobscopy">Jobs</Link>
    //         </li>
    //         <li>
    //           <Link to="/jobuserapply">Applied</Link>
    //         </li>
    //         {/* <li>
    //           <Link to="/jobuserselected">Jobs Selected</Link>
    //         </li> */}
    //         <li>
    //           <Link to="/">Logout</Link>
    //         </li>
    //       </ul>
    //     </div>
    //   </div>

    //   <div className="flex bg-slate-200 min-h-screen">
    //     <nav>
    //       <div className="hidden md:block sm:w-[200px] md:w-[280px] h-full bg-violet-600">
    //         <div className="flex items-center p-5">
    //           {/* <img src={logo} width={50} height={50} /> */}
    //           {/* <h1 className="ml-3">GOOD JOBS</h1> */}
    //           <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
    //         </div>
    //         <ul>
    //           <li className="flex items-center mt-7 px-8">
    //             {/* <img src={profile} width={30} height={30} /> */}

    //             {/* <a href="/userprofile" className="ml-5">
    //               Profile
    //             </a> */}

    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke-width="1.5"
    //               stroke="currentColor"
    //               className="size-8 text-white"
    //             >
    //               <path
    //                 stroke-linecap="round"
    //                 stroke-linejoin="round"
    //                 d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
    //               />
    //             </svg>

    //             <Link to="/userprofile" className="ml-5 font-bold text-white">
    //               Profile
    //             </Link>
    //           </li>
    //           {/* <li className='flex items-center mt-7 px-8'>
    //                         <img src={profile}  width={30} height={30} />
    //                         <a href='/myjobs' className='ml-5'>Jobs</a>
    //                     </li> */}
    //           <li className="flex items-center mt-7 px-8">
    //             {/* <img src={add} width={30} height={30} /> */}

    //             {/* <a href="/jobs" className="ml-5">
    //               Jobs
    //             </a> */}

    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke-width="1.5"
    //               stroke="currentColor"
    //               className="size-7 text-white"
    //             >
    //               <path
    //                 stroke-linecap="round"
    //                 stroke-linejoin="round"
    //                 d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
    //               />
    //             </svg>

    //             <Link to="/jobscopy" className="ml-5 font-bold text-white">
    //               Jobs
    //             </Link>
    //           </li>
    //           <li className="flex items-center mt-7 px-8">
    //             {/* <img src={apply} width={30} height={30} /> */}

    //             {/* <a href="/jobuserapply" className="ml-5">
    //               Jobs Applied
    //             </a> */}

    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke-width="1.5"
    //               stroke="currentColor"
    //               className="size-6 text-white"
    //             >
    //               <path
    //                 stroke-linecap="round"
    //                 stroke-linejoin="round"
    //                 d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
    //               />
    //             </svg>

    //             <Link to="/jobuserapply" className="ml-5 font-bold text-white">
    //               Applied
    //             </Link>
    //           </li>
    //           {/* <li className="flex items-center mt-7 px-8">
                

    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke-width="1.5"
    //               stroke="currentColor"
    //               className="size-7 text-white"
    //             >
    //               <path
    //                 stroke-linecap="round"
    //                 stroke-linejoin="round"
    //                 d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
    //               />
    //             </svg>

    //             <Link
    //               to="/jobuserselected"
    //               className="ml-5 font-bold text-white"
    //             >
    //               Jobs Selected
    //             </Link>
    //           </li> */}
    //           <li className="flex items-center mt-7 px-8">
    //             {/* <img src={logout} width={30} height={30} /> */}

    //             {/* <a href="/" className="ml-5">
    //               Logout
    //             </a> */}

    //             <svg
    //               xmlns="http://www.w3.org/2000/svg"
    //               fill="none"
    //               viewBox="0 0 24 24"
    //               stroke-width="1.5"
    //               stroke="currentColor"
    //               className="size-7 text-white"
    //             >
    //               <path
    //                 stroke-linecap="round"
    //                 stroke-linejoin="round"
    //                 d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
    //               />
    //             </svg>

    //             <Link to="/" className="ml-5 font-bold text-white">
    //               Logout
    //             </Link>
    //           </li>
    //         </ul>
    //       </div>
    //     </nav>

    //     {/* <div>
    //       <div className="m-auto">
    //         <h1 className="text-center text-violet-600 font-bold mt-4">Notifications</h1>
    //         <div className="flex flex-wrap">

    //           <div className="w-full md:w-1/2 h-80 overflow-y-auto bg-violet-500 gap-5 shadow-lg">
    //             <h1 className="text-center font-bold">Unread</h1>

    //             {notification.filter(
    //               (item) => item.user === user1 && item.is_read === false
    //             ).length === 0 ? (
    //               <li className="w-full text-center m-auto font-medium text-violet-800">
    //                 No Notification
    //               </li>
    //             ) : (
    //               notification
    //                 .filter(
    //                   (item) => item.user === user1 && item.is_read === false
    //                 )
    //                 .map((item) => {
    //                   return (
    //                     <div
    //                       className="bg-white mb-2 rounded-lg shadow-xl"
    //                       onClick={() => {
    //                         handleClick(item.id);
    //                         item.description === "Job Added to the portal"
    //                           ? navigate("/jobscopy")
    //                           : navigate("/jobuserapply");
    //                       }}
    //                     >
    //                       <h3 className="">
    //                         {job.find((itm) => itm.id === item.job).title} {""}{" "}
    //                         {item.description}
    //                       </h3>
    //                     </div>
    //                   );
    //                 })
    //             )}
    //           </div>

    //           <div className="w-full md:w-1/2 bg-violet-500 shadow-lg">
    //             <h1 className="text-center font-bold">read</h1>

    //             {notification.filter(
    //               (item) => item.user === user1 && item.is_read === true
    //             ).length === 0 ? (
    //               <li className="w-full text-center mt-11 font-medium text-violet-800">
    //                 No Unread Notification
    //               </li>
    //             ) : (
    //               notification
    //                 .filter(
    //                   (item) => item.user === user1 && item.is_read === true
    //                 )
    //                 .map((item) => {
    //                   return (
    //                     <div
    //                       className="bg-white shadow-xl"
    //                       onClick={() => {
    //                         item.description === "Job Added to the portal"
    //                           ? navigate("/jobscopy")
    //                           : navigate("/jobuserapply");
    //                       }}
    //                     >
    //                       <h3 className="">
    //                         {job.find((itm) => itm.id === item.job).title} {""}{" "}
    //                         {item.description}
    //                       </h3>
    //                     </div>
    //                   );
    //                 })
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div> */}

    //     <div className="bg-slate-200 w-full min-h-screen">
    //       <h1 className="text-center text-violet-600 font-bold mt-5">Notifications</h1>
    //       <div className="flex flex-wrap">
            
    //         <div className="w-full md:w-1/2 p-5 overflow-auto">
    //           <div className="bg-white px-10 py-5 md:min-h-screen rounded-2xl shadow-lg">
    //             <h1 className="text-center font-bold mb-2">Unread</h1>

    //             {notification.filter(
    //               (item) => item.user === user1 && item.is_read === false
    //             ).length === 0 ? (
    //               <li className="w-full text-center m-auto font-medium text-violet-800">
    //                 No Notification
    //               </li>
    //             ) : (
    //               notification
    //                 .filter(
    //                   (item) => item.user === user1 && item.is_read === false
    //                 )
    //                 .map((item) => {
    //                   return (
    //                     <div
    //                       className="bg-violet-300 shadow-lg mb-2 p-3 rounded-md"
    //                       onClick={() => {
    //                         handleClick(item.id);
    //                         item.description === "Job Added to the portal"
    //                           ? navigate("/jobscopy")
    //                           : navigate("/jobuserapply");
    //                       }}
    //                     >
    //                       <h3 className="">
    //                         {job.find((itm) => itm.id === item.job).title} {""}{" "}
    //                         {item.description}
    //                       </h3>
    //                     </div>
    //                   );
    //                 })
    //             )}
    //           </div>
    //         </div>
    //         <div className=" w-full md:w-1/2 p-5">
    //           <div
    //             className="bg-white px-10 py-5 md:min-h-screen rounded-2xl shadow-lg"
    //           >
    //             <h1 className="text-center font-bold mb-2">read</h1>

    //             {notification.filter(
    //               (item) => item.user === user1 && item.is_read === true
    //             ).length === 0 ? (
    //               <li className="w-full text-center mt-11 font-medium text-violet-800">
    //                 No Unread Notification
    //               </li>
    //             ) : (
    //               notification
    //                 .filter(
    //                   (item) => item.user === user1 && item.is_read === true
    //                 )
    //                 .map((item) => {
    //                   return (
    //                     <div
    //                       className="bg-violet-300 shadow-lg mb-2 p-3 rounded-md"
    //                       onClick={() => {
    //                         item.description === "Job Added to the portal"
    //                           ? navigate("/jobscopy")
    //                           : navigate("/jobuserapply");
    //                       }}
    //                     >
    //                       <h3 className="">
    //                         {job.find((itm) => itm.id === item.job).title} {""}{" "}
    //                         {item.description}
    //                       </h3>
    //                     </div>
    //                   );
    //                 })
    //             )}
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    // </div>



//     <div className="bg-slate-200 w-full min-h-screen">
//   <h1 className="text-center text-violet-600 font-bold mt-5">Notifications</h1>
//   <div className="flex flex-wrap justify-center">
    
//     <div className="w-auto md:w-1/2 p-5">
//       <div className="bg-white px-10 py-5 rounded-2xl shadow-lg">
//         <h1 className="text-center font-bold mb-2">Unread</h1>

//         {notification.filter(
//           (item) => item.user === user1 && item.is_read === false
//         ).length === 0 ? (
//           <li className="w-full text-center m-auto font-medium text-violet-800">
//             No Notification
//           </li>
//         ) : (
//           <div className="overflow-y-auto max-h-screen">
//             {notification
//               .filter(
//                 (item) => item.user === user1 && item.is_read === false
//               )
//               .map((item) => {
//                 return (
//                   <div
//                     className="bg-violet-300 shadow-lg mb-2 p-3 rounded-md"
//                     onClick={() => {
//                       handleClick(item.id);
//                       item.description === "Job Added to the portal"
//                         ? navigate("/jobscopy")
//                         : navigate("/jobuserapply");
//                     }}
//                   >
//                     <h3 className="">
//                       {job.find((itm) => itm.id === item.job).title} {""}{" "}
//                       {item.description}
//                     </h3>
//                   </div>
//                 );
//               })}
//           </div>
//         )}
//       </div>
//     </div>

//     <div className="w-auto md:w-1/2 p-5">
//       <div className="bg-white px-10 py-5 rounded-2xl shadow-lg">
//         <h1 className="text-center font-bold mb-2">Read</h1>

//         {notification.filter(
//           (item) => item.user === user1 && item.is_read === true
//         ).length === 0 ? (
//           <li className="w-full text-center mt-11 font-medium text-violet-800">
//             No Read Notification
//           </li>
//         ) : (
//           <div className="overflow-y-auto max-h-screen">
//             {notification
//               .filter(
//                 (item) => item.user === user1 && item.is_read === true
//               )
//               .map((item) => {
//                 return (
//                   <div
//                     className="bg-violet-300 shadow-lg mb-2 p-3 rounded-md"
//                     onClick={() => {
//                       item.description === "Job Added to the portal"
//                         ? navigate("/jobscopy")
//                         : navigate("/jobuserapply");
//                     }}
//                   >
//                     <h3 className="">
//                       {job.find((itm) => itm.id === item.job).title} {""}{" "}
//                       {item.description}
//                     </h3>
//                   </div>
//                 );
//               })}
//           </div>
//         )}
//       </div>
//     </div>
//   </div>
// </div>


<div className="bg-zinc-100 w-80 min-h-screen">
  <h1 className="text-center text-violet-600 font-bold mt-6">Notifications</h1>
  <div className="flex flex-col items-center">
    
    <div className="w-full px-2 py-1">
      <div className=" px-2 py-1 rounded-2xl shadow-lg">
        <h1 className="text-center font-bold mb-2 mt-2"></h1>

        {notification.filter(
          (item) => item.user === user1 && item.is_read === false
        ).length === 0 ? (
          <li className="w-full text-center m-auto font-medium text-violet-800 list-none">
            No Notification
          </li>
        ) : (
          <div className="overflow-y-auto max-h-screen">
            {notification
              .filter(
                (item) => item.user === user1 && item.is_read === false
              )
              .map((item) => {
                return (
                  <div
                    className=" w-full mb-2 px-2 py-1"
                    onClick={() => {
                      handleClick(item.id);
                      item.description === "Job Added to the portal"
                        ? navigate("/jobscopy")
                        : navigate("/jobuserapply");
                    }}
                  >
                    <h3 className="">
                      {job.find((itm) => itm.id === item.job).title} {""}{" "}
                      {item.description}
                    </h3>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div>

    {/* <div className="w-full px-2 py-1">
      <div className="bg-white px-2 py-1 rounded-2xl shadow-lg">
        <h1 className="text-center font-bold mb-2">Read</h1>

        {notification.filter(
          (item) => item.user === user1 && item.is_read === true
        ).length === 0 ? (
          <li className="w-full text-center mt-11 font-medium text-violet-800">
            No Read Notification
          </li>
        ) : (
          <div className="overflow-y-auto max-h-screen">
            {notification
              .filter(
                (item) => item.user === user1 && item.is_read === true
              )
              .map((item) => {
                return (
                  <div
                    className="bg-violet-300 shadow-lg w-full mb-2 px-2 py-1 rounded-md"
                    onClick={() => {
                      item.description === "Job Added to the portal"
                        ? navigate("/jobscopy")
                        : navigate("/jobuserapply");
                    }}
                  >
                    <h3 className="">
                      {job.find((itm) => itm.id === item.job).title} {""}{" "}
                      {item.description}
                    </h3>
                  </div>
                );
              })}
          </div>
        )}
      </div>
    </div> */}
  </div>
</div>




  );
}
