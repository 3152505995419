import { Link } from "react-router-dom";
import React, { useState } from "react";
import logo from "../images/logo.png";
import home from "../images/home.jpg";
import amazon from "../images/amazon.png";
import infosys from "../images/infosys.png";
import hcl from "../images/hcl.png";
import toshiba from "../images/toshiba.png";
import bank from "../images/bank.png";
import wfh from "../images/wfh.png";
import accounts from "../images/accounts.png";
import hr from "../images/hr.png";
import facebook from "../images/facebook.png";
import instagram from "../images/instagram.png";
import whatsapp from "../images/whatsapp.png";
import twitter from "../images/twitter.png";
import review from "../images/review.png";
import landing from "../images/landing.png"
import degree from "../images/degree.png"
import diploma from "../images/diploma.png"
import iti from "../images/iti.png"
import pass from "../images/Pass.png"
import fulltime from "../images/fulltime.png"
import parttime from "../images/parttime.png"
import work from "../images/work.png"
import contract from "../images/contract.png"
import temporary from "../images/temporary.png"

export default function Landingpage() {

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };


  return (
    <div>
      <div>
        <div className="bg-white rounded-2xl p-5">
          <div className="container mx-auto">
            <div className="flex">
              <h5 className="text-3xl font-bold pr-5 text-violet-900">
                Careerhub
              </h5>
              <div className="my-auto">
                <button className="lg:hidden" onClick={toggleMenu}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="size-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
                    />
                  </svg>
                </button>
                <ul className="hidden lg:flex font-bold text-gray-600 ">
                  <li className="pr-5">
                    <Link
                      to=""
                      className="px-3 py-2 border border-purple-100 hover:border-purple-600 rounded text-violet-700"
                    >
                      HOME
                    </Link>
                  </li>
                  <li className="pr-5">
                    <Link
                      to=""
                      className="px-3 py-2 border border-purple-100 hover:border-purple-600 text-violet-700 rounded"
                    >
                      ABOUT
                    </Link>
                  </li>
                  <li className="pr-5">
                    <Link
                      to="/landingpagejobs"
                      className="px-3 py-2 border border-purple-100 hover:border-purple-600 text-violet-700 rounded"
                    >
                      JOBS
                    </Link>
                  </li>
                  <li className="pr-5">
                    <Link
                      to=""
                      className="px-3 py-2 border border-purple-100 hover:border-purple-600 text-violet-700 rounded"
                    >
                      CONTACT
                    </Link>
                  </li>
                  <li className="pr-5">
                    <Link
                      to="/signup"
                      className="px-3 py-2 border border-purple-100 hover:border-purple-600 text-violet-700 rounded"
                    >
                      SIGNUP
                    </Link>
                  </li>
                </ul>
              </div>
              <Link
                to="/login"
                className="ml-auto font-bold px-3 py-2 bg-white border border-violet-600 text-violet-700 rounded"
              >
                LOGIN
              </Link>
            </div>
            {/* <div className="hidden"> */}
            <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
              <ul className=" lg:hidden font-bold text-violet-700 ">
                <li className="mt-1">
                  <Link to="" className=" py-2 ">
                    HOME
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="" className=" py-2 ">
                    ABOUT
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="landingpagejobs" className=" py-2 ">
                    JOBS
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="" className=" py-2 ">
                    CONTACT
                  </Link>
                </li>
                <li className="mt-1">
                  <Link to="/signup" className="py-2 ">
                    SIGNUP
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="bg-violet-500">
          <div className="container mx-auto p-10 flex justify-center items-center">
            <div className="">
              <h1 className="font-semibold text-white md:text-2xl">
                Over <span className="font-extrabold md:text-3xl">8 lakhs</span>{" "}
                jobs to explore
              </h1>
            </div>
            {/* <div className="flex">
              <input
                type="text"
                placeholder="Search jobs"
                className="bg-white border-none rounded-lg w-full p-2 "
              />
              <button className="bg-violet-600 font-bold text-white rounded-xl p-3 ml-5">
                Submit
              </button>
            </div> */}
          </div>
        </div>
      </div>

      <div className="container mx-auto">
        <div className="flex flex-wrap justify-around items-center">
        <div className="order-2 lg:order-1">
          <p className="text-violet-600 font-semibold text-4xl">Unlock your<br/>
            <span className="font-extrabold">Career Potential</span><br/>
            with <span className="font-bold">Careerhub</span>
          </p>
          <button className="mt-5 bg-violet-600 px-4 py-3 rounded-lg text-white font-bold"><Link to="/signup">Signup for free</Link></button>
        </div>
        <div className="order-1 lg:order-2">
          <img src={landing} width={500} />
        </div>
        
        </div>
        

      </div>

      <div className="container mx-auto">
        <div>
          <h1 className="text-center text-violet-700 font-semibold md:font-bold text-xl mt-2">
            Popular Category
          </h1>
        </div>
        <div className="flex flex-wrap justify-evenly mt-4">
          <div className="flex items-center border border-violet-200 px-3 py-2 w-48 mb-3">
            <div>
              <img src={bank} width={20} />
            </div>
            <div className="ml-3">
              <h5 className="">Banking</h5>
            </div>
          </div>

          <div className="flex items-center border border-violet-200 px-3 py-2 w-48 mb-3">
            <div>
              <img src={wfh} width={20} />
            </div>
            <div className="ml-3">
              <h5 className="">Work from Home</h5>
            </div>
          </div>

          <div className="flex items-center border border-violet-200 px-3 py-2 w-48 mb-3">
            <div>
              <img src={accounts} width={20} />
            </div>
            <div className="ml-3">
              <h5 className="">IT</h5>
            </div>
          </div>
          <div className="flex items-center border border-violet-200 px-3 py-2 w-48 mb-3">
            <div>
              <img src={hr} width={20} />
            </div>
            <div className="ml-3">
              <h5 className="">HR</h5>
            </div>
          </div>

          <div className="flex items-center border border-violet-200 px-3 py-2 w-48 mb-3">
            <div>
              <img src={hr} width={20} />
            </div>
            <div className="ml-3">
              <h5 className="">Accounting</h5>
            </div>
          </div>

          <div className="flex items-center border border-violet-200 px-3 py-2 w-48 mb-3">
            <div>
              <img src={hr} width={20} />
            </div>
            <div className="ml-3">
              <h5 className="">Design</h5>
            </div>
          </div>
        </div>
      </div>

      <div className=" rounded-2xl p-5">
        <div className="container mx-auto p-0 lg:px-28">
          <div className=" rounded-2xl p-6">
            <h2 className="font-bold text-2xl text-violet-700 text-center">
              Qualification Based Jobs
            </h2>
            <div className="flex flex-wrap justify-center items-center mt-5">
              <div className="mx-8 mb-3 shadow-lg px-5 py-4 w-28 h-32 flex flex-col justify-center items-center bg-white">
                <img src={degree} width={60} className="mx-auto"  />
                <h2 className="text-center mt-3">Degree</h2>
              </div>
              <div  className="mx-8 mb-3 shadow-lg px-5 py-4 w-28 h-32 flex flex-col justify-center items-center bg-white">
                <img src={diploma} width={50} className="mx-auto"/>
                <h2 className="text-center mt-3">Diploma</h2>
              </div>
              <div  className="mx-8 mb-3 shadow-lg px-5 py-4 w-28 h-32 flex flex-col justify-center items-center bg-white">
                <img src={iti} width={50} className="mx-auto"/>
                <h2 className="text-center mt-3">ITI</h2>
              </div>
              <div  className="mx-8 mb-3 shadow-lg px-5 py-4 w-28 h-32 flex flex-col justify-center items-center bg-white">
                <img src={pass} width={30} className="mx-auto" />
                <h2 className="text-center mt-3">12th Pass</h2>
              </div>

            </div>
          </div>

        </div>
      </div>


      <div className=" rounded-2xl p-5">
        <div className="container mx-auto p-0 lg:px-28">
          <div className=" rounded-2xl p-6">
            <h2 className="font-bold text-2xl text-violet-700 text-center">
              Different Types of Jobs
            </h2>
            <div className="flex flex-wrap justify-center items-center mt-5">
              <div className="mx-8  shadow-lg px-5 py-4 mb-3 w-32 h-32 flex flex-col justify-center">
                <img src={fulltime} width={30} className="mx-auto"  />
                <h2 className="text-center mt-3">Full Time</h2>
              </div>
              <div  className="mx-8 shadow-lg px-5 py-4 mb-3 w-32 h-32 flex flex-col justify-center">
                <img src={parttime} width={30} className="mx-auto"/>
                <h2 className="text-center mt-3">Part Time</h2>
              </div>
              <div  className="mx-8 shadow-lg px-5 py-4 mb-3 w-32 h-32 flex flex-col justify-center">
                <img src={work} width={30} className="mx-auto"/>
                <h2 className="text-center mt-3">Work From Home</h2>
              </div>
              <div  className="mx-8 shadow-lg px-5 py-4 mb-3 w-32 h-32 flex flex-col justify-center">
                <img src={contract} width={30} className="mx-auto" />
                <h2 className="text-center mt-3">Contract</h2>
              </div>
              <div  className="mx-8 shadow-lg px-5 py-4 mb-3 w-32 h-32 flex flex-col justify-center">
                <img src={temporary} width={30} className="mx-auto" />
                <h2 className="text-center mt-3">Temporary</h2>
              </div>

            </div>
          </div>

        </div>
      </div>

      <div className=" rounded-2xl p-5">
        <div className="container mx-auto p-0 lg:px-28">
          <div className="bg-white rounded-2xl p-5 shadow-xl">
            <h2 className="font-bold text-2xl text-violet-600 text-center">
              Our Top Recruiters
            </h2>
            <div className="flex flex-wrap justify-center">
              <div className="p-5 w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                <img src={amazon} className="max-h-8 mx-auto" />
              </div>
              <div className="p-5 w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                <img src={infosys} className="max-h-8 mx-auto" />
              </div>
              <div className="p-5 w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                <img src={hcl} className="max-h-8 mx-auto" />
              </div>
              <div className="p-5 w-full md:w-1/2 lg:w-1/3 xl:w-1/4">
                <img src={toshiba} className="max-h-8 mx-auto" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white p-5">
        <div className="container mx-auto">
          <h2 className="text-center text-violet-600 font-extrabold">
            Our User's Review
          </h2>
          <div className="mt-2">
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3">
              <div className="bg-white shadow-xl rounded-lg p-10">
                <img src={review} width={40} />
                <p className="text-violet-600">
                One of the most popular job sites out there. It's easy to use and has a simple design that makes searching for your next job a breeze. best place to start by doing a general job search. It'll show you what's out there and who's hiring, pointing you in the right direction as you do your job search.
                </p>
                <p className="font-bold text-violet-800 text-lg mt-5">Name : Pradeep </p>
                <p className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </p>
              </div>

              <div className="bg-white shadow-xl rounded-lg p-10">
                <img src={review} width={40} />
                <p className="text-violet-600">
                Top pick for entry-level job seekers. It allows you to post a resume directly onto the site's database to connect with employers. If you're new to the job market, it can save you time as you start your search. There are advanced search filters that allow recent grads to search for jobs
                </p>
                <p className="font-bold text-violet-800 text-lg mt-5">Name : Suresh </p>
                <p className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  
                </p>
              </div>

              <div className="bg-white shadow-xl rounded-lg p-10">
                <img src={review} width={40} />
                <p className="text-violet-600">
                A popular pick among HR professionals. This is helpful to know as you're looking for the best place to find a job because it means hiring managers are regularly posting jobs here. You'll have access to a variety of fresh jobs. This job search site that makes searching for remote jobs easier.
                </p>
                <p className="font-bold text-violet-800 text-lg mt-5">Name : Sandeep </p>
                <p className="flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="size-4 text-violet-600"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.006 5.404.434c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.434 2.082-5.005Z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </p>
              </div>

            </div>
          </div>
        </div>
      </div>

      <footer className=" p-5 mb-5 bg-violet-600 text-white">
        <div className="flex flex-wrap py-6">
          <div className="p-5 w-full md:w-1/3">
            <h2 className="font-bold text-xl">Careerhub</h2>
            {/* <p className="mt-3 font-medium">
            Jobstreet is an award-winning company that provides an easy job search experience and a wide variety of job vacancies in multiple industries across Asia. With over 20 years of experience, we’ve been trusted by millions of working professionals with their careers. We have helped thousands of people start and grow their career.
            </p> */}
            <div className="flex flex-col mt-3 font-medium">
            <Link to="/landingpagejobs">Jobs</Link>
            <Link to="">About Us</Link>
            <Link to="">Contact Us</Link>
            </div>
            
          </div>
          <div className="p-5 w-full md:w-1/3">
            <h2 className="font-bold">Address</h2>
            <p className="mt-3 font-medium">
              100 feet road, <br />
              Gandhipuram, <br />
              Coimbatore <br />
            </p>
          </div>

          <div className="p-5 w-full md:w-1/3">
            <h2 className="font-bold">Inquiries</h2>
            <p className="mt-3 font-medium">
              1234567890 <br />
              careerhub@gmail.com
              <br />
            </p>
            <ul className="flex items-center">
              <li className="mr-3 mt-3">
                <img src={facebook} width={30} />
              </li>
              <li className="mr-3 mt-3">
                <img src={instagram} width={20} />
              </li>
              <li className="mr-3 mt-3">
                <img src={whatsapp} width={25} />
              </li>
              <li className="mt-3">
                <img src={twitter} width={20} />
              </li>
            </ul>
          </div>
        </div>
        <div className="text-center font-medium text-white">
          All Rights Reserved
        </div>
      </footer>
    </div>
  );
}
