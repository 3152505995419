import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../functions/config";

export default function Jobupdate() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [salary, setSalary] = useState("");
  const [location, setLocation] = useState("");
  const [job_type, setJob_type] = useState("");
  const [description, setDescription] = useState("");

  const [department, setDepartment] = useState("");
  const [shift, setShift] = useState("");
  const [required_skills, setRequired_skills] = useState("");
  const [experience_required, setExperience_required] = useState("");

  const shiftOptions = ["Day Shift", "Night Shift"];

  const experienceOptions = [
    { value: "", label: "Select Experience" },
    { value: "Freshers", label: "Freshers" },
    { value: "0", label: "0 Years" },
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Years" },
  ];

  const jobTypeOptions = [
    { value: "", label: "Select Job Type" },
    { value: "Full-time", label: "Full-time" },
    { value: "Part-time", label: "Part-time" },
    { value: "Temporary", label: "Temporary" },
  ];

  const departmentOptions = [
    { value: "", label: "Select Department" },
    { value: "IT", label: "IT" },
    { value: "Banking", label: "Banking" },
    { value: "Accounting", label: "Accounting" },
    { value: "Nursing", label: "Nursing" },
  ];

  const navigate = useNavigate("");

  const { id } = useParams();

  useEffect(() => {
    async function fetchdata() {
      try {
        // const res1 = await axios.get(`${baseUrl}api/v1/ex1/job/${id}`, {
        const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/job/${id}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        });
        if (res1.data.status === 200) {
          console.log(res1);
          setTitle(res1.data.data.title);
          setCompany(res1.data.data.company);
          setSalary(res1.data.data.salary);
          setDescription(res1.data.data.description);
          setLocation(res1.data.data.location);
          setJob_type(res1.data.data.job_type);
          setDepartment(res1.data.data.department);
          setShift(res1.data.data.shift);
          setRequired_skills(res1.data.data.required_skills);
          setExperience_required(res1.data.data.experience_required);
        } else {
          console.log("error 1");
        }
      } catch (error) {}
    }
    fetchdata();
  }, [baseUrl, token]);

  const handleSubmit = async (id) => {
    const data = {
      title: title,
      company: company,
      salary: salary,
      location: location,
      description: description,
      job_type: job_type,

      department: department,
      shift: shift,
      required_skills: required_skills,
      experience_required: experience_required,
    };
    try {
      // const res2 = await axios.put(
      //   `${baseUrl}api/v1/ex1/job/update/${id}`,
      //   data,

      const res2 = await axios.put(
        `${config.baseUrl}api/v1/ex1/job/update/${id}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res2.data.status === 200) {
        console.log(res2);
        toast.success("Job Updated Successfully", {
          autoclose: 1100,
          position: "top-right",
          onClose: () => {
            navigate("/employerd");
          },
        });
      } else {
        console.log("error 2");
        toast.error("Error", {
          autoclose: 1100,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1100,
        position: "top-right",
      });
    }
  };

  return (
    // <div>
    //   <div className="flex justify-center mt-5">
    //     <form
    //       encType="multipart/form-data"
    //       className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md transition-transform transform hover:scale-105"
    //       onSubmit={(e) => {
    //         e.preventDefault();
    //         handleSubmit(id);
    //       }}
    //     >
    //       <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
    //         Job Details
    //       </h2>

    //       <div className="mb-6">
    //         <label
    //           htmlFor="title"
    //           className="block text-sm font-medium text-gray-800"
    //         >
    //           Title :
    //         </label>
    //         <input
    //           type="text"
    //           id="title"
    //           value={title}
    //           onChange={(e) => {
    //             e.preventDefault();
    //             setTitle(e.target.value);
    //           }}
    //           required
    //           className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //         />
    //       </div>

    //       <div className="mb-6">
    //         <label
    //           htmlFor="Company"
    //           className="block text-sm font-medium text-gray-800"
    //         >
    //           Company :
    //         </label>
    //         <input
    //           type="text"
    //           id="company"
    //           value={company}
    //           onChange={(e) => {
    //             e.preventDefault();
    //             setCompany(e.target.value);
    //           }}
    //           required
    //           className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //         />
    //       </div>
    //       <div className="mb-6">
    //         <label
    //           htmlFor="description"
    //           className="block text-sm font-medium text-gray-800"
    //         >
    //           Description :
    //         </label>
    //         <input
    //           type="text"
    //           id="description"
    //           value={description}
    //           onChange={(e) => {
    //             e.preventDefault();
    //             setDescription(e.target.value);
    //           }}
    //           required
    //           className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //         />
    //       </div>

    //       <div className="mb-6">
    //         <label className="block text-sm font-medium text-gray-800">
    //           Job Type
    //         </label>

    //         <select
    //           value={job_type}
    //           onChange={(e) => setJob_type(e.target.value)}
    //           className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
    //         >

    //           <option value="">{job_type}</option>
    //           {jobTypeOptions.map((option, index) => (
    //             <option key={index} value={option.value}>
    //               {option.label}
    //             </option>
    //           ))}
    //         </select>
    //       </div>

    //       <div className="mb-6">
    //         <label
    //           htmlFor="salary"
    //           className="block text-sm font-medium text-gray-800"
    //         >
    //           Salary:
    //         </label>
    //         <input
    //           type="number"
    //           id="salary"
    //           value={salary}
    //           onChange={(e) => {
    //             e.preventDefault();
    //             setSalary(e.target.value);
    //           }}
    //           required
    //           className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //         />
    //       </div>
    //       <div className="mb-6">
    //         <label
    //           htmlFor="location"
    //           className="block text-sm font-medium text-gray-700 "
    //         >
    //           Location:
    //         </label>
    //         <input
    //           id="location"
    //           type="text"
    //           placeholder="Enter the location"
    //           value={location}
    //           onChange={(e) => {
    //             e.preventDefault();
    //             setLocation(e.target.value);
    //           }}
    //           className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 bg-gray-50"
    //         />
    //       </div>

    //       <div className="mb-6">
    //         <label className="block text-sm font-medium text-gray-800">
    //           Department
    //         </label>
    //         <select
    //           value={department}
    //           onChange={(e) => setDepartment(e.target.value)}
    //           className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
    //         >

    //       <option value="">{department}</option>

    //           {departmentOptions.map((option, index) => (
    //             <option key={index} value={option.value}>
    //               {option.label}
    //             </option>
    //           ))}
    //         </select>
    //       </div>

    //       <div className="mb-6">
    //         <label className="block text-sm font-medium text-gray-800">
    //           Shift
    //         </label>
    //         <select
    //           value={shift}
    //           onChange={(e) => setShift(e.target.value)}
    //           className="w-full border-2 border-gray-300 p-2 rounded mt-2"
    //         >
              
    //           <option value="">{shift}</option>
    //           {shiftOptions.map((option, index) => (
    //             <option key={index} value={option}>
    //               {option}
    //             </option>
    //           ))}
    //         </select>
    //       </div>

    //       <div className="mb-6">
    //         <label className="block text-sm font-medium text-gray-800">
    //           Experience Required
    //         </label>
    //         <select
    //           value={experience_required}
    //           onChange={(e) => setExperience_required(e.target.value)}
    //           className="w-full border-2 border-gray-300 p-2 rounded mt-2 overflow-y-auto"
    //         >

    //           <option value="">{experience_required}</option>
    //           {experienceOptions.map((option, index) => (
    //             <option key={index} value={option.value}>
    //               {option.label}
    //             </option>
    //           ))}
    //         </select>
    //       </div>

    //       <div className="mb-6">
    //         <label
    //           htmlFor="required_skills"
    //           className="block text-sm font-medium text-gray-800"
    //         >
    //           Required_Skills :
    //         </label>
    //         <input
    //           type="text"
    //           id="required_skills"
    //           placeholder="Enter the required skills"
    //           value={required_skills}
    //           onChange={(e) => {
    //             e.preventDefault();
    //             setRequired_skills(e.target.value);
    //           }}
    //           required
    //           className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //         />
    //       </div>

    //       <button
    //         type="submit"
    //         className="w-full bg-violet-600 text-white font-semibold py-3 rounded-lg hover:bg-violet-500 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300"
    //       >
    //         Submit
    //       </button>

    //       <Link
    //         to={`/job/${id}`}
    //         className="block text-center font-semibold text-violet-800 mt-4 hover:underline"
    //       >
    //         {" "}
    //         My Jobs
    //       </Link>
    //     </form>
    //   </div>
    // </div>

    // <div>
    //   <div className="container mx-auto">
    //     <div className="flex flex-wrap">
    //       <div>
    //         <form
    //           encType="multipart/form-data"
    //           className=""
              
    //         >
    //           <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
    //             Job Details
    //           </h2>

    //           <div className="mb-6">
    //             <label
    //               htmlFor="title"
    //               className="block text-sm font-medium text-gray-800"
    //             >
    //               Title :
    //             </label>
    //             <input
    //               type="text"
    //               id="title"
    //               value={title}
    //               onChange={(e) => {
    //                 e.preventDefault();
    //                 setTitle(e.target.value);
    //               }}
    //               required
    //               className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //             />
    //           </div>

    //           <div className="mb-6">
    //             <label
    //               htmlFor="Company"
    //               className="block text-sm font-medium text-gray-800"
    //             >
    //               Company :
    //             </label>
    //             <input
    //               type="text"
    //               id="company"
    //               value={company}
    //               onChange={(e) => {
    //                 e.preventDefault();
    //                 setCompany(e.target.value);
    //               }}
    //               required
    //               className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //             />
    //           </div>
    //           <div className="mb-6">
    //             <label
    //               htmlFor="description"
    //               className="block text-sm font-medium text-gray-800"
    //             >
    //               Description :
    //             </label>
    //             <input
    //               type="text"
    //               id="description"
    //               value={description}
    //               onChange={(e) => {
    //                 e.preventDefault();
    //                 setDescription(e.target.value);
    //               }}
    //               required
    //               className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //             />
    //           </div>

    //           <div className="mb-6">
    //             <label className="block text-sm font-medium text-gray-800">
    //               Job Type
    //             </label>

    //             <select
    //               value={job_type}
    //               onChange={(e) => setJob_type(e.target.value)}
    //               className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
    //             >
    //               <option value="">{job_type}</option>
    //               {jobTypeOptions.map((option, index) => (
    //                 <option key={index} value={option.value}>
    //                   {option.label}
    //                 </option>
    //               ))}
    //             </select>
    //           </div>

    //           <div className="mb-6">
    //             <label
    //               htmlFor="salary"
    //               className="block text-sm font-medium text-gray-800"
    //             >
    //               Salary:
    //             </label>
    //             <input
    //               type="number"
    //               id="salary"
    //               value={salary}
    //               onChange={(e) => {
    //                 e.preventDefault();
    //                 setSalary(e.target.value);
    //               }}
    //               required
    //               className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //             />
    //           </div>
    //           <div className="mb-6">
    //             <label
    //               htmlFor="location"
    //               className="block text-sm font-medium text-gray-700 "
    //             >
    //               Location:
    //             </label>
    //             <input
    //               id="location"
    //               type="text"
    //               placeholder="Enter the location"
    //               value={location}
    //               onChange={(e) => {
    //                 e.preventDefault();
    //                 setLocation(e.target.value);
    //               }}
    //               className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 bg-gray-50"
    //             />
    //           </div>
    //         </form>
    //       </div>
    //       <div>
    //         <form encType="multipart/form-data"
    //         onSubmit={(e) => {
    //           e.preventDefault();
    //           handleSubmit(id);
    //         }}>
    //           <div className="mb-6">
    //             <label className="block text-sm font-medium text-gray-800">
    //               Department
    //             </label>
    //             <select
    //               value={department}
    //               onChange={(e) => setDepartment(e.target.value)}
    //               className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
    //             >
    //               <option value="">{department}</option>

    //               {departmentOptions.map((option, index) => (
    //                 <option key={index} value={option.value}>
    //                   {option.label}
    //                 </option>
    //               ))}
    //             </select>
    //           </div>

    //           <div className="mb-6">
    //             <label className="block text-sm font-medium text-gray-800">
    //               Shift
    //             </label>
    //             <select
    //               value={shift}
    //               onChange={(e) => setShift(e.target.value)}
    //               className="w-full border-2 border-gray-300 p-2 rounded mt-2"
    //             >
                  
    //               <option value="">{shift}</option>
    //               {shiftOptions.map((option, index) => (
    //                 <option key={index} value={option}>
    //                   {option}
    //                 </option>
    //               ))}
    //             </select>
    //           </div>

    //           <div className="mb-6">
    //             <label className="block text-sm font-medium text-gray-800">
    //               Experience Required
    //             </label>
    //             <select
    //               value={experience_required}
    //               onChange={(e) => setExperience_required(e.target.value)}
    //               className="w-full border-2 border-gray-300 p-2 rounded mt-2 overflow-y-auto"
    //             >
    //               <option value="">{experience_required}</option>
    //               {experienceOptions.map((option, index) => (
    //                 <option key={index} value={option.value}>
    //                   {option.label}
    //                 </option>
    //               ))}
    //             </select>
    //           </div>

    //           <div className="mb-6">
    //             <label
    //               htmlFor="required_skills"
    //               className="block text-sm font-medium text-gray-800"
    //             >
    //               Required_Skills :
    //             </label>
    //             <input
    //               type="text"
    //               id="required_skills"
    //               placeholder="Enter the required skills"
    //               value={required_skills}
    //               onChange={(e) => {
    //                 e.preventDefault();
    //                 setRequired_skills(e.target.value);
    //               }}
    //               required
    //               className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
    //             />
    //           </div>

    //           <button
    //             type="submit"
    //             className="w-full bg-violet-600 text-white font-semibold py-3 rounded-lg hover:bg-violet-500 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300"
    //           >
    //             Submit
    //           </button>

    //           <Link
    //             to={`/job/${id}`}
    //             className="block text-center font-semibold text-violet-800 mt-4 hover:underline"
    //           >
    //             My Jobs
    //           </Link>
    //         </form>
    //       </div>
    //     </div>
    //   </div>
    // </div>



    <div className=" min-h-screen flex items-center justify-center bg-slate-100">
  <div className="container mx-auto p-4 sm:p-8 md:p-12 bg-white rounded-lg shadow-xl w-full max-w-4xl">

    <h2 className="text-2xl font-bold text-center text-violet-600 mb-2">
            Job Details
          </h2>


    <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
      {/* First Form (Job Details) */}
      <div>
        <form encType="multipart/form-data">
          {/* <h2 className="text-2xl font-bold text-center text-gray-800 mb-6">
            Job Details
          </h2> */}

          <div className="mb-4">
            <label htmlFor="title" className="block text-sm font-medium text-gray-800">
              Title :
            </label>
            <input
              type="text"
              id="title"
              value={title}
              onChange={(e) => setTitle(e.target.value)}
              required
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="company" className="block text-sm font-medium text-gray-800">
              Company :
            </label>
            <input
              type="text"
              id="company"
              value={company}
              onChange={(e) => setCompany(e.target.value)}
              required
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
            />
          </div>

          <div className="mb-4">
            <label htmlFor="description" className="block text-sm font-medium text-gray-800">
              Description :
            </label>
            <input
              type="text"
              id="description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              required
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
            />
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              Job Type
            </label>
            <select
              value={job_type}
              onChange={(e) => setJob_type(e.target.value)}
              className="w-full border-2 border-gray-300 p-2 rounded mt-2"
            >
              <option value="">{job_type}</option>
              {jobTypeOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="">
            <label htmlFor="salary" className="block text-sm font-medium text-gray-800">
              Salary :
            </label>
            <input
              type="number"
              id="salary"
              value={salary}
              onChange={(e) => setSalary(e.target.value)}
              required
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
            />
          </div>

          {/* <div className="mb-6">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              Location :
            </label>
            <input
              id="location"
              type="text"
              placeholder="Enter the location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
            />
          </div> */}
        </form>
      </div>

      {/* Second Form (Additional Info) */}
      <div>
        <form encType="multipart/form-data"
         onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(id);
                  }}>
        <div className="mb-4">
            <label htmlFor="location" className="block text-sm font-medium text-gray-700">
              Location :
            </label>
            <input
              id="location"
              type="text"
              placeholder="Enter the location"
              value={location}
              onChange={(e) => setLocation(e.target.value)}
              className="mt-1 block w-full p-3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500 bg-gray-50"
            />
          </div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              Department
            </label>
            <select
              value={department}
              onChange={(e) => setDepartment(e.target.value)}
              className="w-full border-2 border-gray-300 p-2 rounded mt-2"
            >
              <option value="">{department}</option>
              {departmentOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              Shift
            </label>
            <select
              value={shift}
              onChange={(e) => setShift(e.target.value)}
              className="w-full border-2 border-gray-300 p-2 rounded mt-2"
            >
              <option value="">{shift}</option>
              {shiftOptions.map((option, index) => (
                <option key={index} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-800">
              Experience Required
            </label>
            <select
              value={experience_required}
              onChange={(e) => setExperience_required(e.target.value)}
              className="w-full border-2 border-gray-300 p-2 rounded mt-2"
            >
              <option value="">{experience_required}</option>
              {experienceOptions.map((option, index) => (
                <option key={index} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>

          <div className="mb-3">
            <label htmlFor="required_skills" className="block text-sm font-medium text-gray-800">
              Required Skills :
            </label>
            <input
              type="text"
              id="required_skills"
              placeholder="Enter the required skills"
              value={required_skills}
              onChange={(e) => setRequired_skills(e.target.value)}
              required
              className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
            />
          </div>

          <button
            type="submit"
            className="w-full bg-violet-600 text-white font-semibold py-3 rounded-lg hover:bg-violet-500 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300"
            
          >
            Submit
          </button>

          {/* <Link
            to={`/job/${id}`}
            className="block text-center font-semibold text-violet-800 mt-4 hover:underline"
          >
            My Jobs
          </Link> */}
        </form>
      </div>
    </div>
    <div className="flex justify-center">
    <Link
            to={`/job/${id}`}
            className="block text-center font-semibold text-white w-14 px-3 py-1 rounded-xl mt-4 hover:underline bg-violet-600"
          >
            Back
          </Link>
    </div>
    
  </div>
</div>




  );
}
