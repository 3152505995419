import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Signup from './components/Signup';
import Login from './components/Login';

import Jobadd from './components/Jobadd';

import Apply from './components/Apply';
import Jobdetails from './components/Jobdetails';

import Jobupdate from './components/Jobupdate';
import Userprofile from './components/Userprofile';
import Userprofileadd from './components/Userprofileadd';

import Jobapplied from './components/Jobapplied';
import Jobselected from './components/Jobselected';
import Jobappdetails from './components/Jobappdetails';
import Employerd from './components/Employerd';
import Userprofiles from './components/Userprofiles';
import Jobuserapply from './components/Jobuserapply';
import Jobuserselected from './components/Jobuserselected';
import Userprofileupdate from './components/Userprofileupdate';
import Changepassword from './components/Changepassword';
import Userprofilesupd from './components/Userprofilesupd';
import Forgotpassword from './components/Forgotpassword';
import Resetpassword from './components/Resetpassword';
import Landingpage from './components/Landingpage';
import Jobscopy from './components/Jobscopy';
import Jobselecteddetails from './components/Jobselecteddetails';
import Landingpagejobs from './components/Landingpagejobs';
import Jobuserapplydetails from './components/Jobuserapplydetails';
import Jobsuserdetails from './components/Jobsuserdetails';
import Landingpagejobsdetails from './components/Landingpagejobsdetails';
import Notification from './components/Notification';








function App() {

  // localStorage.setItem('baseUrl', 'http://127.0.0.1:8000/');

  // localStorage.setItem('baseUrl', 'https://myprojectlive.infoxtechnologies.com/');

  localStorage.setItem('baseUrl', 'https://myprojectlivebackend.infoxtechnologies.com/');

  return (
    <div>
      <ToastContainer/>
      <Router>
        <Routes>

          
          <Route path='/signup' element={< Signup/>} />
          <Route path='/login' element={< Login/>} />
          

          <Route path='/forgot-password' element={<Forgotpassword/>}/>
          <Route path='/reset-password/:uidb64/:token' element={< Resetpassword/>} />
          

          <Route path='/landingpagejobs' element={<Landingpagejobs/>}/>
          <Route path='/landingpagejobsdetails/:id' element={<Landingpagejobsdetails/>}/>
          


          
          <Route path='/employerd' element={<Employerd/>}/>

          <Route path='/notification' element={< Notification />} />
          
          

          
          <Route path='/jobs/add' element={< Jobadd/>} />
          <Route path='/job/:id' element={< Jobdetails/>} />
          
          <Route path='/update/:id' element={< Jobupdate/>} />


          <Route path='/apply/:id' element={<Apply/>} />
          <Route path='/jobuserapply' element={<Jobuserapply/>} />
          {/* <Route path='/jobuserselected' element={<Jobuserselected/>} /> */}


          <Route path='/jobselecteddetails/:id' element={<Jobselecteddetails/>} />
          <Route path='/jobuserapplydetails/:id' element={<Jobuserapplydetails/>} />
          <Route path='/jobsuserdetails/:id' element={<Jobsuserdetails/>}/>
          


          <Route path='/jobapplied' element={<Jobapplied/>} />
          <Route path='/jobselected' element={<Jobselected/>} />
          <Route path='/jobapp/:id' element={<Jobappdetails/>} />


          <Route path='/userprofile' element={<Userprofile/>} />
          <Route path='/userprofile/profile/update/:id' element={<Userprofileupdate/>} />
          <Route path='/changepass' element={< Changepassword/>} />



          <Route path='/userprofiles' element={<Userprofiles/>} />
          <Route path='/userprofiles/add' element={<Userprofileadd/>} />
          <Route path='/userprofile/userprofiles/update/:id' element={<Userprofilesupd/>} />

          <Route path='/' element={<Landingpage/>}/>
          <Route path='/jobscopy' element={<Jobscopy/>}/>

          





        </Routes>
      </Router>
     
    </div>
  );
}

export default App;
