import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import config from "../functions/config";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Jobappdetails() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);

  const [obj, setObj] = useState({});
  const [job, setJob] = useState([]);
  const [user, setUser] = useState([]);

  const [status, setStatus] = useState({});

  const { id } = useParams();

  const navigate = useNavigate()

  const isAccepted = status[obj.id]?.accepted;
  const isRejected = status[obj.id]?.rejected;

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await axios.get(`${baseUrl}api/v1/ex1/jobapp/${id}`, {
          const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp/${id}`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        // const res1 = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
          const res1 = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        // const res2 = await axios.get(`${baseUrl}api/v1/ex1/user`, {
          const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/user`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });

        if (response.data.status === 200) {
          console.log(response);
          setObj(response.data.data);
        } else {
          console.log("error 1");
        }

        if (res1.data.status === 200) {
          console.log(res1);
          setJob(res1.data.data);
        } else {
          console.log("error 2");
        }

        if (res2.data.status === 200) {
          console.log(res2);
          setUser(res2.data.data);
        } else {
          console.log("error 2");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token, id]);

  const handleClick1 = async (id) => {
    setLoading(true);
    const data = {
      is_accepted: true,
    };
    try {
      // const res3 = await axios.put(
      //   `${baseUrl}api/v1/ex1/jobapp/accept/${id}`,
      //   data,

        const res3 = await axios.put(
          `${config.baseUrl}api/v1/ex1/jobapp/accept/${id}`,
          data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res3.data.status === 200) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [id]: { accepted: true, rejected: false },
        }));
        console.log(res3);
        setLoading(false)
        toast.success("Application Selected", {
          autoclose: 1000,
          position: "top-right",
          onClose: () => {
            navigate("/jobapplied");
          },
        });
        
      } else {
        console.log("error 3");
        toast.error("Error", {
          autoclose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1000,
        position: "top-right",
      });
    }
  };

  const handleClick2 = async (id) => {
    setLoading1(true)
    const data = {
      is_rejected: true,
    };
    try {
      // const res4 = await axios.put(
      //   `${baseUrl}api/v1/ex1/jobapp/reject/${id}`,
      //   data,

        const res4 = await axios.put(
          `${config.baseUrl}api/v1/ex1/jobapp/reject/${id}`,
          data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res4.data.status === 200) {
        setStatus((prevStatus) => ({
          ...prevStatus,
          [id]: { accepted: false, rejected: true },
        }));
        console.log(res4);
        setLoading1(false)
        toast.success("Application Rejected", {
          autoclose: 1000,
          position: "top-right",
          onClose: () => {
            navigate("/jobapplied");
          },
        });
      } else {
        console.log("error 4");
        toast.error("Error", {
          autoclose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1000,
        position: "top-right",
      });
    }
  };

  const handleClick3 = async (id) => {
    const data = {
      is_applied: false,
      is_rejected: false,
      is_allowed: false,
    };
    try {
      // const res5 = await axios.put(
      //   `${baseUrl}api/v1/ex1/jobapp/readd/${id}`,
      //   data,

        const res5 = await axios.put(`${config.baseUrl}api/v1/ex1/jobapp/readd/${id}`,data,
        {
         
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res5.data.status === 200) {
        console.log(res5);
        toast.success("Added to Jobs Successfully", {
          autoclose: 1000,
          position: "top-right",
          
        });
      } else {
        console.log("error 5");
        toast.error("Error", {
          autoclose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1000,
        position: "top-right",
      });
    }
  };

  return (
    <div>

<div className="">
        <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-62 bg-violet-600`}
        >
          <ul className="space-y-2 px-4 py-4 text-[16px] text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to="/employerd">Jobs</Link>
            </li>
            <li>
              <Link to="/jobs/add">Add Jobs</Link>
            </li>
            <li>
              <Link to="/jobapplied">Applications</Link>
            </li>
            {/* <li>
              <Link to="/jobselected">Selected</Link>
            </li> */}
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex">
        <nav className="hidden md:block bg-violet-600  w-[120px] md:w-[280px] lg:w-[320px] min-h-screen shadow-md">
          <div className="flex items-center p-5">
            
            <h5 className="text-lg md:text-xl lg:text-2xl font-bold pr-5 text-white">
            Careerhub
            </h5>
          </div>
          <ul className="mt-7">
            <li className="flex items-center mt-7 px-8">
              

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-8 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                />
              </svg>
              <Link to="/userprofile" className="ml-5 font-bold text-white">
                Profile
              </Link>
            </li>

            <li className="flex items-center mt-7 px-8">
              

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-7 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                />
              </svg>

              <Link to="/employerd" className="ml-5 font-bold text-white">
                Jobs
              </Link>
            </li>
            <li className="flex items-center mt-7 px-8">
              

            <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

              <Link to="/jobs/add" className="ml-5 font-bold text-white">
                {" "}
                Add Jobs
              </Link>
            </li>
            <li className="flex items-center mt-7 px-8">
              

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-6 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                />
              </svg>

              <Link to="/jobapplied" className="ml-5 font-bold text-white">
                Applications
              </Link>
            </li>
            {/* <li className="flex items-center mt-7 px-8">
              

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-7 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                />
              </svg>

              <Link to="/jobselected" className="ml-5 font-bold text-white">
                Applications Selected
              </Link>
            </li> */}
            <li className="flex items-center mt-7 px-8">
              

              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                className="size-7 text-white"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                />
              </svg>

              <Link to="/" className="ml-5 font-bold text-white">
                {" "}
                Logout
              </Link>
            </li>
          </ul>
        </nav>

        <div className="flex-1 p-6 bg-slate-100 overflow-auto">
          <div className="bg-white p-5 shadow-lg rounded-md">
            <h3 className="text-xl text-violet-600 font-bold mb-3">
              Application Details
            </h3>
            <div className="space-y-4">
              <div>
                <h4 className="text-lg">
                  Title: <span className="font-bold">{job.find((item) => item.id === obj.job)?.title}</span>
                </h4>
              </div>

              <h4 className="text-lg">
                Company: <span className="font-semibold"> {job.find((item) => item.id === obj.job)?.company}</span>
              </h4>
              <h4 className="text-lg">
                Description:{" "}
                {job.find((item) => item.id === obj.job)?.description}
              </h4>
              <div className="flex items-center gap-1">
              <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        className="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                        />
                      </svg>
                      <h4 className="text-lg">
                Job Type: <span className="font-bold">{job.find((item) => item.id === obj.job)?.job_type}</span>
              </h4>
              </div>
              

              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                  />
                </svg>
                <h4 className="text-lg">
                  Location: <span className="font-bold"> {job.find((item) => item.id === obj.job)?.location}</span>
                </h4>
              </div>

              <div className="flex items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>
                <h4 className="text-lg">
                  Salary: <span className="font-bold">{job.find((item) => item.id === obj.job)?.salary}</span>
                </h4>
              </div>

              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                  />
                </svg>
                <h4 className="text-lg">
                  Name: <span className="font-bold">{user.find((item) => item.id === obj.user)?.first_name}{" "}
                  {user.find((item) => item.id === obj.user)?.last_name}</span>
                </h4>
              </div>

              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                  />
                </svg>
                <h4 className="text-lg">
                  Email: <span className="font-bold">{user.find((item) => item.id === obj.user)?.username}</span>
                </h4>
              </div>

              <div className="flex items-center gap-1">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-5"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                  />
                </svg>
                <h4 className="text-lg">
                  Phone: <span className="font-bold"> {user.find((item) => item.id === obj.user)?.phone}</span>
                </h4>
              </div>

              <h4 className="text-lg">
                Resume:{" "}
                {/* <a href={obj.resume} className="text-blue-500">
                  {obj.resume}
                </a> */}
                
                <a
                  href={`${obj.resume}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-violet-800"
                >
                  <span className="font-semibold">Click Here</span>
                </a>
                {/* <Link to={obj.resume} className="text-violet-600">{obj.resume}</Link> */}
              </h4>

              <div className="flex items-center gap-1 mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>

                    <h4 className="text-lg">
                      Applied on : <span className="font-bold">{obj.applied_on}</span>
                    </h4>
                  </div>

              <div className="flex gap-4 mt-4">
                     
                     

                     
                      {!isAccepted && !isRejected && (
                        <>
                          <button
                            className="px-2 py-1 bg-violet-600 text-white font-bold rounded-3xl w-24 hover:bg-violet-600 transition duration-300"
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick1(obj.id);
                            }}
                          >
                            {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Accept"
                )}
                            
                          </button>
                          <button
                            className="px-2 py-1 bg-violet-600 text-white font-bold rounded-3xl w-24 hover:bg-violet-600 transition duration-300"
                            onClick={(e) => {
                              e.preventDefault();
                              handleClick2(obj.id);
                            }}
                          >
                            {loading1 ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Reject"
                )}

                            
                          </button>
                        </>
                      )}
                    </div>

            </div>
          </div>
        </div>

        
      </div>
    </div>
  );
}
