// base_url:"http://127.0.0.1:8000/"
// baseUrl :"https://myprojectlive.infoxtechnologies.com/",

const config = {
    baseUrl :"https://myprojectlivebackend.infoxtechnologies.com/",

    // baseUrl :"http://127.0.0.1:8000/",
    
}
export default config

