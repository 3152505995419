import { Link, useNavigate } from "react-router-dom";
import React, { useEffect, useState } from "react";
import axios from "axios";
import config from "../functions/config";

export default function Jobscopy() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [array, setArray] = useState([]);
  const [user, setUser] = useState([]);
  const navigate = useNavigate();
  const [locationInput, setLocationInput] = useState("");
  const [jobTypeInput, setJobTypeInput] = useState("");
  const [titleInput, setTitleInput] = useState("");
  const [obj, setObj] = useState({});
  const [selectedJobId, setSelectedJobId] = useState(""); // State to track the selected job ID

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  useEffect(() => {
    async function fetchData() {
      try {
        // const response = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
          const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {

          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
          // params: {
          //   location: locationInput,
          //   job_type: jobTypeInput,
          //   title: titleInput,
          // },
        });

        if (response.data.status === 200) {
          const jobs = response.data.data;
          setArray(jobs);
          setUser(response.data.user);

          // Filter out jobs that are already applied to (is_allowed === true)
          const availableJobs = jobs.filter((job) => job.is_allowed === false);

          if (availableJobs.length > 0) {
            // Set the first available job as the default selected job
            setSelectedJobId(availableJobs[0].id);
            handleClick1(availableJobs[0].id); // Load the first available job details
          }
        } else {
          console.log("Error fetching job data.");
        }
      } catch (error) {
        console.log("Error fetching data");
      }
    }

    fetchData();
  }, [baseUrl, token, locationInput, jobTypeInput, titleInput]);

  const handleClick = async (id) => {
    const data = {
      is_allowed: true,
    };
    try {
      // const res1 = await axios.put(
      //   `${baseUrl}api/v1/ex1/job/allow/${id}`,
      //   data,

        const res1 = await axios.put(
          `${config.baseUrl}api/v1/ex1/job/allow/${id}`,
          data,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res1.data.status === 200) {
        console.log(res1);
        navigate(`/apply/${id}`);
      } else {
        console.log("Error in applying for the job.");
      }
    } catch (error) {
      console.log("Error during application.");
    }
  };

  const handleClick1 = async (id) => {
    try {
      // const res2 = await axios.get(`${baseUrl}api/v1/ex1/job/${id}`, {
        const res2 = await axios.get(`${config.baseUrl}api/v1/ex1/job/${id}`, {

        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
      });
      if (res2.data.status === 200) {
        console.log(res2);
        setObj(res2.data.data);
      } else {
        console.log("Error fetching job details.");
      }
    } catch (error) {
      console.log("Error while fetching job details.");
    }
  };

  const handleClick2 = async () => {
    try {
      // const res3 = await axios.get(`${baseUrl}api/v1/ex1/profile`, {
        const res3 = await axios.get(`${config.baseUrl}api/v1/ex1/profile`, {

        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token ${token}`,
        },
      });
      if (res3.data.status === 200) {
        console.log(res3);
        // setProfile(res3.data.data);
      } else {
        console.log("Error fetching profile.");
      }
    } catch (error) {
      console.log("Error fetching profile details.");
    }
  };

  const handleJobSearch = async () => {
    try {
      // const response = await axios.get(`${baseUrl}api/v1/ex1/jobs`, {
        const response = await axios.get(`${config.baseUrl}api/v1/ex1/jobs`, {

        headers: {
          "Content-Type": "application/json",
          Authorization: `Token ${token}`,
        },
        params: {
          location: locationInput,
          job_type: jobTypeInput,
          title: titleInput,
        },
      });

      if (response.data.status === 200) {
        const jobs = response.data.data;
        setArray(jobs);
        setUser(response.data.user);

        // Filter out jobs that are already applied to (is_allowed === true)
        const availableJobs = jobs.filter((job) => job.is_allowed === false);

        if (availableJobs.length > 0) {
          // Set the first available job as the default selected job
          setSelectedJobId(availableJobs[0].id);
          handleClick1(availableJobs[0].id); // Load the first available job details
        }
      } else {
        console.log("Error fetching job data.");
      }
    } catch (error) {
      console.log("Error fetching data");
    }
  };

  const handleSearchClick = (e) => {
    e.preventDefault();
    handleJobSearch(); // Trigger the job search API call
  };

  return (
    <div>
      <div className="w-full px-3 py-6 flex justify-between items-center bg-violet-600 rounded">
        <div className="flex items-center">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
        </div>

        <div>
          <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
        </div>

        {/* <div className="hidden md:flex items-center ">
          <input
            type="text"
            placeholder="Search jobs"
            className="bg-white border-none rounded-lg w-full p-2 border border-violet-600 "
          />
          <button className=" bg-violet-600 font-bold text-white rounded-xl p-3 ml-5">
            Search
          </button>
        </div> */}

        <div className="hidden md:flex items-center gap-3">
          <input
            type="text"
            placeholder="Search By Title"
            className="rounded-xl w-full py-1"
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
          />

          {/* <input
            type="text"
            placeholder="Search By Job Type"
            className="border border-r-black  p-2 w-32"
            value={jobTypeInput}
            onChange={(e) => setJobTypeInput(e.target.value)}
          /> */}

          <select
            className=" rounded-xl w-full px-1 py-1"
            value={locationInput}
            onChange={(e) => setLocationInput(e.target.value)}
          >
            <option value="" disabled hidden selected>
              Location
            </option>
            <option value="Chennai">Chennai</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Coimbatore">Coimbatore</option>
          </select>

          <button
            onClick={handleSearchClick}
            className="bg-white text-violet-600 font-extrabold px-2 w-24 rounded-2xl ml-3"
          >
            Search
          </button>
        </div>

        
      </div>
      {/* <div className={`lg:hidden ${isMenuOpen ? 'block' : 'hidden'}`}> */}
      {/* <div className={`lg:hidden ${isMenuOpen ? 'translate-x-0' : '-translate-x-full'} transition-transform duration-300 ease-in-out`}>
          <ul className=" bg-slate-300">
            <li><Link to="">Profile</Link></li>
            <li><Link to="">Jobs</Link></li>
            <li><Link to="">Jobs Applied</Link></li>
            <li><Link to="">Jobs Selected</Link></li>
            <li><Link to="">Logout</Link></li>
          </ul>
        </div> */}

      <div
        className={` ${
          isMenuOpen ? "translate-x-0" : "-translate-x-full"
        } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-30 bg-violet-500`}
      >
        <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/landingpagejobs">Jobs</Link>
          </li>
          
        </ul>
      </div>

      <div className="py-5">
        <div className="flex flex-wrap justify-center items-center md:hidden px-10 gap-3">
          {/* <input
            type="text"
            placeholder="Search jobs"
            className="bg-white rounded-lg w-full p-2 border border-violet-600"
          />
          <button className=" bg-violet-600 font-bold text-white rounded-xl p-1 ml-5">
            Search
          </button> */}
          
          <input
            type="text"
            placeholder="Title"
            className=" rounded-lg w-full border border-gray-600"
            value={titleInput}
            onChange={(e) => setTitleInput(e.target.value)}
          />

          {/* <input
            type="text"
            placeholder="Search Job Type"
            className="border border-r-black  p-2 w-32"
            value={jobTypeInput}
            onChange={(e) => setJobTypeInput(e.target.value)}
          /> */}

          <select
            className=" w-full rounded-lg border border-gray-600"
            value={locationInput}
            onChange={(e) => setLocationInput(e.target.value)}
          >
            <option value="" disabled hidden selected>
              Location
            </option>
            <option value="Chennai">Chennai</option>
            <option value="Bangalore">Bangalore</option>
            <option value="Coimbatore">Coimbatore</option>
          </select>

          <button
            onClick={handleSearchClick}
            className="bg-violet-600 text-white font-bold w-24 rounded-2xl py-1 ml-1"
          >
            Search
          </button>
        </div>
      </div>

      

      <div className="my-4">
        <div className="bg-white rounded-lg">
          <h1 className="text-violet-600 font-bold text-center text-xl mb-2">
            Jobs
          </h1>
          <div className="flex mx-auto max-w-screen-lg">
            <div className="w-full md:w-1/2 h-screen overflow-auto">
              <ul className="flex flex-wrap gap-6 p-4">
                {array
                  .filter((item) => item.is_allowed === false) // Only show jobs that are not applied yet
                  .map((item) => (
                    <li
                      key={item.id}
                      className="bg-white border border-violet-800 rounded-lg shadow-2xl p-6 w-full h-full"
                    >
                      <div>
                        <div
                          onClick={() => {
                            setSelectedJobId(item.id); // Set selected job ID on click
                            handleClick1(item.id); // Fetch the job details
                          }}
                        >
                          <h3 className="text-xl font-semibold text-black mb-1">
                            {item.title}
                          </h3>
                          <h3 className="text-gray-500 font-medium mb-1">
                            {item.company}
                          </h3>

                          <div className="flex flex-wrap gap-4">
                            <div className="flex items-center gap-1 bg-slate-100 px-2 py-1 rounded">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-5 text-gray-500"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                                />
                              </svg>
                              <h3 className=" text-sm font-semibold text-gray-500 mb-1">
                                {item.salary} per month
                              </h3>
                            </div>

                            <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-5 text-gray-500"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                                />
                              </svg>
                              <h3 className="text-sm font-semibold text-gray-500 mb-1">
                                {item.job_type}
                              </h3>
                            </div>

                            <div className="flex items-center gap-1 bg-slate-100 px-2 py-1">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="currentColor"
                                className="size-5 text-gray-500"
                              >
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M15 10.5a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                                />
                                <path
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                  d="M19.5 10.5c0 7.142-7.5 11.25-7.5 11.25S4.5 17.642 4.5 10.5a7.5 7.5 0 1 1 15 0Z"
                                />
                              </svg>
                              <h3 className="text-sm font-semibold text-gray-500 mb-1">
                                {item.location}
                              </h3>
                            </div>
                            <div>
                            <h4 className=" font-sm  text-gray-500 h-6 overflow-auto">
                              Required Skills: {item.required_skills}
                            </h4>
                            </div>
                            
                            <h4 className=" font-sm  text-gray-700">
                              Posted on: {item.posted_on}
                            </h4>

                            <div className="mx-auto  md:hidden ">
                              <button className="bg-violet-600 text-white font-semibold rounded-2xl px-2 py-1 mt-1">
                                <Link to={`/landingpagejobsdetails/${item.id}`}>
                                  View Details
                                </Link>
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>

            <div className="hidden md:block w-full md:w-1/2 sticky top-0 bg-white shadow-xl rounded mx-auto h-screen overflow-auto">
              {selectedJobId ? (
                <div className="ml-3">
                  <h2 className="text-2xl font-bold text-gray-600 mb-2">
                    {obj.title}
                  </h2>
                  <h2 className="text-lg font-medium text-gray-600 mb-2">
                    {obj.company}
                  </h2>
                  <h3 className="text-lg text-gray-600 mb-2">
                    {obj.description}
                  </h3>
                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M15 8.25H9m6 3H9m3 6-3-3h1.5a3 3 0 1 0 0-6M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                      />
                    </svg>

                    <h4 className="text-lg font-semibold text-gray-700">
                      Salary : {obj.salary} per month
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 0 0 .75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 0 0-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0 1 12 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 0 1-.673-.38m0 0A2.18 2.18 0 0 1 3 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 0 1 3.413-.387m7.5 0V5.25A2.25 2.25 0 0 0 13.5 3h-3a2.25 2.25 0 0 0-2.25 2.25v.894m7.5 0a48.667 48.667 0 0 0-7.5 0M12 12.75h.008v.008H12v-.008Z"
                      />
                    </svg>

                    <h4 className="text-lg font-semibold text-gray-700">
                      Job Type: {obj.job_type}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-5"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M12 3v2.25m6.364.386-1.591 1.591M21 12h-2.25m-.386 6.364-1.591-1.591M12 18.75V21m-4.773-4.227-1.591 1.591M5.25 12H3m4.227-4.773L5.636 5.636M15.75 12a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0Z"
                      />
                    </svg>
                    <h4 className="text-lg font-semibold text-gray-700">
                      Shift: {obj.shift}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M3.75 21h16.5M4.5 3h15M5.25 3v18m13.5-18v18M9 6.75h1.5m-1.5 3h1.5m-1.5 3h1.5m3-6H15m-1.5 3H15m-1.5 3H15M9 21v-3.375c0-.621.504-1.125 1.125-1.125h3.75c.621 0 1.125.504 1.125 1.125V21"
                      />
                    </svg>
                    <h4 className="text-lg font-semibold text-gray-700">
                      Department: {obj.department}
                    </h4>
                  </div>

                  <div className="flex gap-2 mb-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-8"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                      />
                    </svg>
                    <h4 className="text-lg font-semibold text-gray-700">
                      Required Skills: {obj.required_skills}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.633 10.25c.806 0 1.533-.446 2.031-1.08a9.041 9.041 0 0 1 2.861-2.4c.723-.384 1.35-.956 1.653-1.715a4.498 4.498 0 0 0 .322-1.672V2.75a.75.75 0 0 1 .75-.75 2.25 2.25 0 0 1 2.25 2.25c0 1.152-.26 2.243-.723 3.218-.266.558.107 1.282.725 1.282m0 0h3.126c1.026 0 1.945.694 2.054 1.715.045.422.068.85.068 1.285a11.95 11.95 0 0 1-2.649 7.521c-.388.482-.987.729-1.605.729H13.48c-.483 0-.964-.078-1.423-.23l-3.114-1.04a4.501 4.501 0 0 0-1.423-.23H5.904m10.598-9.75H14.25M5.904 18.5c.083.205.173.405.27.602.197.4-.078.898-.523.898h-.908c-.889 0-1.713-.518-1.972-1.368a12 12 0 0 1-.521-3.507c0-1.553.295-3.036.831-4.398C3.387 9.953 4.167 9.5 5 9.5h1.053c.472 0 .745.556.5.96a8.958 8.958 0 0 0-1.302 4.665c0 1.194.232 2.333.654 3.375Z"
                      />
                    </svg>
                    <h4 className="text-lg font-semibold text-gray-700">
                      Experience: {obj.experience_required}
                    </h4>
                  </div>

                  <div className="flex items-center gap-2 mb-1">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      className="size-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5"
                      />
                    </svg>

                    <h4 className=" font-semibold text-gray-800">
                      Posted on: {obj.posted_on}
                    </h4>
                  </div>

                  <button
                    className="mt-4 bg-violet-600 text-white font-bold rounded-3xl w-20 px-4 py-1"
                    onClick={() => navigate("/login")}
                  >
                    Apply
                  </button>
                </div>
              ) : (
                <p>Select a job to view details</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
