import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import config from "../functions/config";
import pro_pic from "../images/pro_pic.jpeg";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Userprofile() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const user_type = localStorage.getItem("user_type");

  const [user, setUser] = useState({});
  const [email, setEmail] = useState("");
  const [data, setData] = useState({});

  const [count, setCount] = useState(0);
  const [countp, setCountp] = useState(0);

  const [resume, setResume] = useState("");
  const [skills, setSkills] = useState("");
  const [experience, setExperience] = useState("");
  const [education, setEducation] = useState("");

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const [isEducationOpen, setIsEducationOpen] = useState(false); // Add state for mobile menu
  const [isSkillsOpen, setIsSkillsOpen] = useState(false); // Add state for mobile menu
  const [isExperienceOpen, setIsExperienceOpen] = useState(false); // Add state for mobile menu
  const [isResumeOpen, setIsResumeOpen] = useState(false); // Add state for mobile menu

  const [isEducationUpdateOpen, setIsEducationUpdateOpen] = useState(false);
  const [isSkillsUpdateOpen, setIsSkillsUpdateOpen] = useState(false);
  const [isExperienceUpdateOpen, setIsExperienceUpdateOpen] = useState(false);
  const [isResumeUpdateOpen, setIsResumeUpdateOpen] = useState(false);

  const [loading, setLoading] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  const toggleEducation = () => {
    setIsEducationOpen(!isEducationOpen);
  };

  const toggleSkills = () => {
    setIsSkillsOpen(!isSkillsOpen);
  };

  const toggleExperience = () => {
    setIsExperienceOpen(!isExperienceOpen);
  };

  const toggleResume = () => {
    setIsResumeOpen(!isResumeOpen);
  };

  const toggleEducationUpdate = () => {
    setIsEducationUpdateOpen(!isEducationUpdateOpen);
  };

  const toggleSkillsUpdate = () => {
    setIsSkillsUpdateOpen(!isSkillsUpdateOpen);
  };

  const toggleExperienceUpdate = () => {
    setIsExperienceUpdateOpen(!isExperienceUpdateOpen);
  };

  const toggleResumeUpdate = () => {
    setIsResumeUpdateOpen(!isResumeUpdateOpen);
  };

  useEffect(() => {
    async function fetchdata() {
      try {
        const response = await axios.get(
          `${config.baseUrl}api/v1/ex1/profile`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (response.data.status === 200) {
          console.log(response);
          setUser(response.data.data);
        } else {
          console.log("error 1");
        }

        const res1 = await axios.get(
          `${config.baseUrl}api/v1/ex1/userprofile`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token ${token}`,
            },
          }
        );

        if (res1.data.status === 200) {
          console.log(res1);
          setData(res1.data.data);
          setEducation(res1.data.data.education);
          setSkills(res1.data.data.skills);
          setExperience(res1.data.data.experience);
          setResume(res1.data.data.resume);
        } else {
          console.log("error 2");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token, count, countp]);

  const handleSubmit1 = async () => {
    const data = {
      resume: resume,
      
      experience: experience,
      // education: education,
    };

    try {
      const res2 = await axios.post(
        `${config.baseUrl}api/v1/ex1/userprofile/add`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(res2);
      if (res2.data.status === 200) {
        console.log(res2);
        toast.success("Added Successfully", {
          autoClose: 1000,
          position: "top-right",
        });
        setCount(count + 1);
      } else {
        console.log("error 21");
        toast.error("Error", {
          autoClose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoClose: 1000,
        position: "top-right",
      });
    }
  };

  const handleSubmit3 = async () => {
    const data = {
      // resume: resume,
      skills: skills,
      // experience: experience,
      // education: education,
    };

    try {
      const res2 = await axios.post(
        `${config.baseUrl}api/v1/ex1/userprofile/add`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(res2);
      if (res2.data.status === 200) {
        console.log(res2);
        toast.success("Added Successfully", {
          autoClose: 1000,
          position: "top-right",
        });
        setCount(count + 1);
      } else {
        console.log("error 21");
        toast.error("Error", {
          autoClose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoClose: 1000,
        position: "top-right",
      });
    }
  };

  const handleSubmit4 = async () => {
    const data = {
      // resume: resume,
      // skills: skills,
      // experience: experience,
      education: education,
    };

    try {
      const res2 = await axios.post(
        `${config.baseUrl}api/v1/ex1/userprofile/add`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(res2);
      if (res2.data.status === 200) {
        console.log(res2);
        toast.success("Added Successfully", {
          autoClose: 1000,
          position: "top-right",
        });
        setCount(count + 1);
      } else {
        console.log("error 21");
        toast.error("Error", {
          autoClose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoClose: 1000,
        position: "top-right",
      });
    }
  };

  const handleSubmit2 = async () => {
    // setLoading(true);
    const data = {
      resume: resume,
      skills: skills,
      experience: experience,
      education: education,
    };
    try {
      const res3 = await axios.put(
        `${config.baseUrl}api/v1/ex1/userprofile/update/`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      console.log(res3);
      if (res3.data.status === 200) {
        console.log(res3);
        toast.success("Updated Successfully", {
          autoClose: 1000,
          position: "top-right",
        });
        setCountp(countp + 1);
        // setLoading(false);
      } else {
        console.log("error 3");
        toast.error("Error", {
          autoClose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log(error);
      toast.error("Error", {
        autoClose: 1000,
        position: "top-right",
      });
    }
  };

  return (
    <div className="relative">
      <div className="">
        <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-62 bg-violet-600`}
        >
          <ul className="space-y-2 px-4 py-4 text-[16px] text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to={user_type === "user" ? "/jobscopy" : "/employerd"}>
                Jobs
              </Link>
            </li>

            {user_type === "employer" && (
              <li>
                <Link to="/jobs/add">Add Jobs</Link>
              </li>
            )}

            <li>
              <Link to={user_type === "user" ? "/jobuserapply" : "/jobapplied"}>
                {user_type === "user" ? "Applied" : "Applications"}
              </Link>
            </li>

            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </div>
      <div className="flex bg-slate-200 min-h-screen">
        <nav>
          <div className=" hidden md:block sm:w-[200px] md:w-[280px] h-full bg-violet-600">
            <div className="flex items-center p-5">
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>

              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link
                  to={user_type === "user" ? "/jobscopy" : "/employerd"}
                  className="ml-5 font-bold text-white"
                >
                  Jobs
                </Link>
              </li>

              {user_type === "employer" && (
                <li className="flex items-center mt-7 px-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-7 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>

                  <Link to="/jobs/add" className="ml-5 font-bold text-white">
                    Add Jobs
                  </Link>
                </li>
              )}

              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link
                  to={user_type === "user" ? "/jobuserapply" : "/jobapplied"}
                  className="ml-5 font-bold text-white"
                >
                  {user_type === "user" ? "Applied" : "Applications"}
                </Link>
              </li>

              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="w-full md:w-1/2 m-auto">
        <h3 className="text-center text-xl text-violet-800 font-bold">
            Profile
          </h3>
          <div className="w-full ">
            <div className=" container mx-auto">
              <div className="flex flex-wrap">
                <div className="w-full px-3 py-2 mx-2 bg-white rounded-lg shadow-2xl">
                  <div className="">
                    <div className="flex justify-between items-center">
                      <div className="flex gap-1 items-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke-width="1.5"
                          stroke="currentColor"
                          class="size-5"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
                          />
                        </svg>

                        <h1 className=" font-bold text-gray-900">
                          {user.first_name} {user.last_name}
                        </h1>
                      </div>

                      <img
                        src={user.image ? user.image : pro_pic}
                        alt="User"
                        className=" w-30 h-28 rounded-full"
                      />
                    </div>

                    <div className="flex items-center gap-2 mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 0 1-2.25 2.25h-15a2.25 2.25 0 0 1-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25m19.5 0v.243a2.25 2.25 0 0 1-1.07 1.916l-7.5 4.615a2.25 2.25 0 0 1-2.36 0L3.32 8.91a2.25 2.25 0 0 1-1.07-1.916V6.75"
                        />
                      </svg>

                      <h1 className=" font-bold text-gray-900">
                        {user.username}
                      </h1>
                    </div>
                    <div className="flex items-center gap-2 mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
                        />
                      </svg>

                      <h1 className=" font-bold text-gray-900">{user.phone}</h1>
                    </div>

                    <div className="flex items-center gap-2 mb-4">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="m2.25 12 8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                        />
                      </svg>

                      <h1 className=" font-bold text-gray-900">
                        {user.address}
                      </h1>
                    </div>

                    <div className="flex items-center gap-2 mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke-width="1.5"
                        stroke="currentColor"
                        class="size-5"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          d="M6 12 3.269 3.125A59.769 59.769 0 0 1 21.485 12 59.768 59.768 0 0 1 3.27 20.875L5.999 12Zm0 0h7.5"
                        />
                      </svg>

                      <h1 className=" font-bold text-gray-900">
                        {user.user_type}
                      </h1>
                    </div>

                    <div className="flex space-x-4 mb-3">
                      <button
                        className={`w-16 bg-violet-600 hover:bg-violet-600 text-white font-bold px-2 py-1 rounded-2xl ${
                          user_type === "user" ? "text-sm" : ""
                        }`}
                      >
                        <Link to="profile/update/:id">Edit</Link>
                      </button>
                      <button
                        className={`bg-violet-600 hover:bg-violet-600 text-white font-bold px-2 py-1 rounded-2xl ${
                          user_type === "user" ? "text-xs" : ""
                        }`}
                      >
                        <Link to="/changepass">Change Password</Link>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {user.user_type === "user" && (
            <div className="w-full mt-2">
              <div className="container mx-auto">
                <div className="flex flex-wrap">
                  <div className="w-full px-3 py-2 mx-2 bg-white rounded-lg shadow-2xl">
                    <div>
                      <div className="flex justify-between items-center mb-4">
                        <div className="flex gap-5 items-center">
                          <label className="block text-sm font-medium text-gray-700">
                            Education:
                          </label>
                          {/* <h1 className=" font-bold text-gray-900">
                            {data.education}
                          </h1> */}

                          <div className="bg-gray-100 rounded-md border border-gray-300 flex flex-wrap gap-2 overflow-y-auto">
                            {(data.education || "")
                              .split(",")
                              .map((education, index) => (
                                <div
                                  key={index}
                                  className="bg-gray-200 px-2 py-1 rounded-md"
                                >
                                  <h1 className="font-bold text-sm text-gray-900">
                                    {education.trim()}
                                  </h1>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="flex gap-1">
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleEducationUpdate();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-4 text-white"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                              />
                            </svg>
                          </button>
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleEducation();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-5 text-white font-bold"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="flex justify-between items-center mb-4">
                        <div className="flex flex-col md:flex-row gap-2 md:gap-12 md:items-center">
                          <label className="block text-sm font-medium text-gray-700">
                            Skills:
                          </label>
                          {/* <h1 className=" text-wrap font-bold text-gray-900 w-64 border border-black overflow-auto">
                        {data.skills}
                      </h1> */}

                          <div className="bg-gray-100 rounded-md border border-gray-300 flex flex-wrap gap-2 overflow-y-auto">
                            {(data.skills || "")
                              .split(",")
                              .map((skill, index) => (
                                <div
                                  key={index}
                                  className="bg-gray-200 p-2 rounded-md"
                                >
                                  <h1 className="font-bold text-sm text-gray-900">
                                    {skill.trim()}
                                  </h1>
                                </div>
                              ))}
                          </div>
                        </div>
                        <div className="flex gap-1">
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleSkillsUpdate();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-4 text-white"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                              />
                            </svg>
                          </button>
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleSkills();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-5 text-white font-bold"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="flex justify-between items-center mb-4">
                        <div className="flex gap-5 items-center">
                          <label className="block text-sm font-medium text-gray-700">
                            Experience:
                          </label>
                          <h1 className=" font-bold text-gray-900">
                            {data.experience}
                          </h1>
                        </div>
                        <div className="flex gap-1">
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleExperienceUpdate();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-4 text-white"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                              />
                            </svg>
                          </button>
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleExperience();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-5 text-white font-bold"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>

                      <div className="flex justify-between items-center">
                        <div className="flex gap-8 items-center">
                          <label className="block text-sm font-medium text-gray-700">
                            Resume:
                          </label>
                          {!data.resume ? (
                            <span className="font-bold text-violet-600">
                              Add Resume
                            </span>
                          ) : (
                            <a
                              href={`${data.resume}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span className="font-bold text-violet-600">
                                Resume
                              </span>
                            </a>
                          )}
                        </div>
                        <div className="flex gap-1">
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleResumeUpdate();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-4 text-white"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                              />
                            </svg>
                          </button>
                          <button
                            className="bg-violet-600 px-2 py-1 rounded"
                            onClick={() => {
                              toggleResume();
                            }}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke-width="1.5"
                              stroke="currentColor"
                              class="size-5 text-white font-bold"
                            >
                              <path
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                d="M12 4.5v15m7.5-7.5h-15"
                              />
                            </svg>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>

        <div
          className={` ${
            isEducationOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
         >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleEducation();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">
                  Add Education
                </label>
                <input
                  id="education"
                  type="text"
                  placeholder="Enter Education"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit4();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={` ${
            isSkillsOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
        >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleSkills();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">Add Skills</label>
                <input
                  id="skills"
                  type="text"
                  placeholder="Enter Skills"
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit3();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={` ${
            isExperienceOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
        >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleExperience();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">
                  Add Experience
                </label>
                <input
                  id="experience"
                  type="number"
                  placeholder="Enter experience (in number)"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit1();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={` ${
            isResumeOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
        >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleResume();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">Add Resume</label>
                <input
                  id="resume"
                  type="file"
                  onChange={(e) => setResume(e.target.files[0])}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit1();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={` ${
            isEducationUpdateOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
        >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleEducationUpdate();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">
                  Update Education
                </label>
                <input
                  id="education"
                  type="text"
                  placeholder="Update Education"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit2();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={` ${
            isResumeUpdateOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
        >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleResumeUpdate();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">
                  Update Resume
                </label>
                <input
                  id="resume"
                  type="file"
                  onChange={(e) => setResume(e.target.files[0])}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit2();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={` ${
            isSkillsUpdateOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
        >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleSkillsUpdate();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">
                  Update Skills
                </label>
                <input
                  id="skills"
                  type="text"
                  placeholder="Enter Skills"
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit2();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>

        <div
          className={` ${
            isExperienceUpdateOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 ease-in-out fixed bottom-28 md:bottom-32 right-0 w-60 h-62 bg-violet-600`}
        >
          <div>
            <div className="bg-white float-end">
              <button
                onClick={() => {
                  toggleExperienceUpdate();
                }}
              >
                <span className="font-bold mr-2">X</span>
              </button>
            </div>
            <form
              className="bg-white rounded shadow-lg p-5"
              encType="multipart/form-data"
            >
              <div className="text-center">
                <label className="text-violet-600 font-bold">
                  Update Experience
                </label>
                <input
                  id="experience"
                  type="number"
                  placeholder="Enter experience (in number)"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
              <div className="text-center">
                <button
                  className="mt-2 bg-violet-600 text-white font-bold px-2 py-1 rounded-3xl"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSubmit2();
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
