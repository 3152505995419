import React, { useState } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../functions/config";
import fg4 from "../images/fg4.jpeg";

export default function ResetPassword() {
  const { uidb64, token } = useParams();

  const baseUrl = localStorage.getItem("baseUrl");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const email = localStorage.getItem("email");

  const navigate = useNavigate();

  // Validation function for the new password
  const validatePassword = (password) => {
    const minLength = 8;
    const upperCasePattern = /[A-Z]/;
    const numberPattern = /\d/;
    const specialCharPattern = /[!@#$%^&*(),.?":{}|<>]/;

    if (password.length < minLength) {
      return "Password must be at least 8 characters long.";
    }
    if (!upperCasePattern.test(password)) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!numberPattern.test(password)) {
      return "Password must contain at least one number.";
    }
    if (!specialCharPattern.test(password)) {
      return "Password must contain at least one special character.";
    }
    return ""; // Password is valid
  };

  const handlePasswordReset = async (e) => {
    const data = {
      new_password: newPassword,
      confirm_password: confirmPassword,
    };

    // Clear previous messages
    setError("");
    setSuccess("");

    // Validation checks
    if (!newPassword || !confirmPassword) {
      setError("Both new password and confirm password are required.");
      return;
    }

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    // Validate the new password
    const passwordValidationError = validatePassword(newPassword);
    if (passwordValidationError) {
      setError(passwordValidationError);
      return;
    }

    setIsLoading(true);

    try {
      // const response = await axios.post(`${baseUrl}api/v1/auth/password-reset/confirm/${uidb64}/${token}`,data,{

      const response = await axios.post(
        `${config.baseUrl}api/v1/auth/password-reset/confirm/${uidb64}/${token}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        console.log(response);
        setSuccess("Your password has been successfully updated.");
        toast.success("Password Updated Successfully!", {
          autoClose: 1100,
          position: "top-right",
          onClose: () => {
            navigate("/login");
          },
        });
        // navigate('/login')
      } else {
        console.log("error 1");
        toast.error("Error", {
          autoClose: 1000,
          position: "top-right",
        });
      }
    } catch (err) {
      if (err.response) {
        setError(err.response.data.error || "Something went wrong.");
      } else {
        setError("Network error, please try again later.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="container mx-auto max-w-4xl p-4">
        <div className="flex flex-wrap justify-center items-center bg-white shadow-xl rounded-lg">
          {/* Form Section */}
          <div className="w-full sm:w-1/2 p-4 order-2 md:order-1">
            <h2 className="text-2xl font-semibold text-center mb-6">
              Reset Your Password
            </h2>

            {email && (
              <div className="text-center mb-4">
                <p className="text-gray-700">
                  Resetting the password for:
                  <span className="font-medium">{email}</span>{" "}
                </p>
              </div>
            )}

            <form
              onSubmit={(e) => {
                e.preventDefault();
                handlePasswordReset(e);
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="newPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  New Password:
                </label>
                <input
                  type="password"
                  id="newPassword"
                  name="newPassword"
                  value={newPassword}
                  onChange={(e) => setNewPassword(e.target.value)}
                  required
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              <div className="mb-4">
                <label
                  htmlFor="confirmPassword"
                  className="block text-sm font-medium text-gray-700"
                >
                  Confirm Password:
                </label>
                <input
                  type="password"
                  id="confirmPassword"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  required
                  className="mt-2 w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>

              {error && (
                <div className="text-red-600 text-sm mb-4">{error}</div>
              )}
              {success && (
                <div className="text-green-600 text-sm mb-4">{success}</div>
              )}

              <button
                type="submit"
                disabled={isLoading}
                className={`w-full py-2 text-white font-bold rounded-3xl focus:outline-none ${
                  isLoading
                    ? "bg-gray-400"
                    : "bg-violet-600 hover:bg-violet-600"
                }`}
              >
                {isLoading ? "Processing..." : "Reset Password"}
              </button>
            </form>
          </div>

          {/* Image Section */}
          <div className="w-full sm:w-1/2 p-4 flex justify-center order-1 md:order-2">
            <img
              src={fg4}
              alt="Reset password illustration"
              className="w-full h-auto max-w-xs sm:max-w-md"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
