import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import React, { useState } from "react";
import bg1 from "../images/bg1.jpg";

import login from "../images/login.jpeg";
import { useNavigate } from "react-router-dom";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import config from "../functions/config";

export default function Login() {
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [loading, setLoading] = useState(false);

  const [user, setUser] = useState("");
  const [donor, setDonor] = useState("");
  // const [user_type, setUser_type] = useState({});

  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");
  const user_type = localStorage.getItem("user_type");

  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async () => {
    setLoading(true);
    const data = {
      email: username,
      password: password,
    };
    try {
      // const response = await axios.post(`${baseUrl}api/v1/auth/login`, data, {

      const response = await axios.post(
        `${config.baseUrl}api/v1/auth/login`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response.data.status === 200) {
        console.log(response);
        localStorage.setItem("token", response.data.token);
        localStorage.setItem("user_type", response.data.user_type);
        setLoading(false);

        const from = location.state?.from || "/";

        if (response.data.is_admin === true) {
          localStorage.setItem("admin", "t");
          // window.location.href = "/mypets";
          toast.success("Admin Logined Successfully", {
            autoClose: 1100,
            position: "top-right",
            // onClose: () => {
            //   navigate("");
            // },
          });
        } else {
          console.log("error 1");
        }

        if (response.data.user_type === "user") {
          localStorage.setItem("token", response.data.token);
          // localStorage.setItem("user", "t");
          localStorage.setItem("user_type", response.data.user_type);

          localStorage.setItem("user", "t");

          // window.location.href = "/pets";
          toast.success("User Logined Successfully", {
            autoClose: 1100,
            position: "top-right",
            onClose: () => {
              navigate("/jobscopy");
            },
          });
        } else if (response.data.user_type === "employer") {
          localStorage.setItem("token", response.data.token);

          // localStorage.setItem("employer", "t");

          localStorage.setItem("user_type", response.data.user_type);

          localStorage.setItem("employer", "t");

          toast.success("Employer Logined Successfully", {
            autoClose: 1100,
            position: "top-right",
            onClose: () => {
              navigate("/employerd");
            },
          });
          // window.location.href = "/donation";
        } else {
          // console.log("error 2");
        }
      } else {
        console.log(response);
        toast.error("Username and Password Incorrect", {
          autoClose: 1100,
          position: "top-right",
        });

        setLoading(false);
      }
    } catch (err) {
      console.log("Error2", err);
      toast.error("Error", {
        autoClose: 1100,
        position: "top-right",
      });
      setLoading(false);
    }
  };

  return (
    <div className="m-auto">
      <section className="bg-slate-200 min-h-screen flex items-center justify-center">
        {/* LOGIN CONTAINER: */}
        <div className="bg-white flex flex-col md:flex-row rounded-2xl shadow-xl max-w-3xl p-5">
          {/* FORM */}
          <div className="w-full md:w-1/2 px-4 md:px-8 lg:px-16 order-2 md:order-1">
            <h2 className="font-bold text-2xl text-violet-600 text-center">Login</h2>
            {/* <p className="text-sm mt-4">
              If you are already a member, easily log in
            </p> */}

            <form className="flex flex-col">
              <label className="mt-4 text-gray-600 font-semibold" htmlFor="email">
                Email
              </label>
              <input
                className="p-2 rounded-xl border border-violet-400 focus:outline-none"
                id="email"
                type="text"
                placeholder="Enter Email"
                value={username}
                onChange={(e) => {
                  e.preventDefault();
                  setUsername(e.target.value);
                }}
              />

              <label htmlFor="password" className="mt-4 text-gray-600 font-semibold">
                Password
              </label>
              <input
                className="p-2 rounded-xl border border-violet-400 focus:outline-none"
                id="password"
                type="password"
                placeholder="Enter Password"
                value={password}
                onChange={(e) => {
                  e.preventDefault();
                  setPassword(e.target.value);
                }}
              />
              {/* <a href="/changepass" className="text-blue-600 hover:underline">
            Change Password
          </a> */}

              <Link to="/forgot-password" className="mt-2 text-gray-600 font-semibold">
                Forgot Password
              </Link>

              <button
                className="bg-violet-600 rounded-xl w-full md:w-1/2 text-white py-2 m-auto mt-4 hover:scale-105 duration-300 hover:bg-violet-600 font-bold"
                type="submit"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit();
                }}
              >
                {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Login"
                )}
              </button>
            </form>

            <div>
              <p className="text-center mt-4">OR</p>
            </div>
            <div>
              {/* <p className="text-center">
                Click Here to
                <button className="bg-violet-600 rounded-xl text-white py-2 mt-1 ml-2 hover:scale-105 duration-300 hover:bg-violet-600 font-bold">
                  

                  <Link to="/signup">Signup</Link>
                </button>
              </p> */}

              <h4>
                Don't have an account?
                <Link to="/signup" className=" ml-2 font-bold text-violet-600">
                  Signup
                </Link>
              </h4>
            </div>
          </div>

          {/* IMAGE */}
          <div className="w-full md:w-1/2 p-5 m-auto order-1 md:order-2">
            <img className="rounded-2xl w-full h-auto" src={bg1} alt="Login" />
          </div>
        </div>
      </section>
    </div>
  );
}


// import { Link, useLocation } from "react-router-dom";
// import axios from "axios";
// import React, { useEffect, useState } from "react";
// import bg1 from "../images/bg1.jpg";

// import ForgotPassword from "../components/Forgotpassword";
// import ResetPassword from "../components/Resetpassword";

// import login from "../images/login.jpeg";
// import { useNavigate } from "react-router-dom";

// import { toast } from "react-toastify";
// import "react-toastify/dist/ReactToastify.css";

// import config from "../functions/config";

// export default function Login() {
//   const [username, setUsername] = useState("");
//   const [password, setPassword] = useState("");

//   const [loading, setLoading] = useState(false);

//   const [isForgotPasswordOpen, setIsForgotPasswordOpen] = useState(false);
//   const [isResetPasswordOpen, setIsResetPasswordOpen] = useState(false);

//   const [uid, setUid] = useState(""); // To store the uid
//   const [token, setToken] = useState(localStorage.getItem("token") || ""); 

//   const [user, setUser] = useState("");
//   const [donor, setDonor] = useState("");
//   // const [user_type, setUser_type] = useState({});

//   const baseUrl = localStorage.getItem("baseUrl");
//   // const token = localStorage.getItem("token");
//   const user_type = localStorage.getItem("user_type");

//   const navigate = useNavigate();
//   const location = useLocation();

//   useEffect(() => {
//     // If the token changes or is updated in localStorage, update the state
//     const storedToken = localStorage.getItem("token");
//     if (storedToken && storedToken !== token) {
//       setToken(storedToken); // Sync the token state with the localStorage value
//     }
//   }, [token]);

//   const handleSubmit = async () => {
//     setLoading(true);
//     const data = {
//       email: username,
//       password: password,
//     };
//     try {
//       const response = await axios.post(`${baseUrl}api/v1/auth/login`, data, {

//       // const response = await axios.post(
//       //   `${config.baseUrl}api/v1/auth/login`,
//       //   data,
//       //   {
//           headers: {
//             "Content-Type": "application/json",
//           },
//         }
//       );
//       if (response.data.status === 200) {
//         console.log(response);
//         localStorage.setItem("token", response.data.token);
//         localStorage.setItem("user_type", response.data.user_type);
//         setLoading(false);

//         const from = location.state?.from || "/";

//         if (response.data.is_admin === true) {
//           localStorage.setItem("admin", "t");
//           // window.location.href = "/mypets";
//           toast.success("Admin Logined Successfully", {
//             autoClose: 1100,
//             position: "top-right",
//             // onClose: () => {
//             //   navigate("");
//             // },
//           });
//         } else {
//           console.log("error 1");
//         }

//         if (response.data.user_type === "user") {
//           localStorage.setItem("token", response.data.token);
//           // localStorage.setItem("user", "t");
//           localStorage.setItem("user_type", response.data.user_type);

//           localStorage.setItem("user", "t");

//           // window.location.href = "/pets";
//           toast.success("User Logined Successfully", {
//             autoClose: 1100,
//             position: "top-right",
//             onClose: () => {
//               navigate("/jobscopy");
//             },
//           });
//         } else if (response.data.user_type === "employer") {
//           localStorage.setItem("token", response.data.token);

//           // localStorage.setItem("employer", "t");

//           localStorage.setItem("user_type", response.data.user_type);

//           localStorage.setItem("employer", "t");

//           toast.success("Employer Logined Successfully", {
//             autoClose: 3000,
//             position: "top-right",
//             onClose: () => {
//               navigate("/employerd");
//             },
//           });
//           // window.location.href = "/donation";
//         } else {
//           // console.log("error 2");
//         }
//       } else {
//         console.log(response);
//         toast.error("Username and Password Incorrect", {
//           autoClose: 3000,
//           position: "top-right",
//         });

//         setLoading(false);
//       }
//     } catch (err) {
//       console.log("Error2", err);
//       toast.error("Error", {
//         autoClose: 3000,
//         position: "top-right",
//       });
//       setLoading(false);
//     }
//   };

//   const toggleForgotPassword = () => {
//     setIsForgotPasswordOpen(!isForgotPasswordOpen);
//   };

//   const toggleResetPassword = () => {
//     setIsResetPasswordOpen(!isResetPasswordOpen);
//   };

//   const onResetSuccess = (uid, token) => {
//     setUid(uid);
//     setToken(token);
//     setIsResetPasswordOpen(true); // Open Reset Password Modal
//   };

//   return (
//     <div className="m-auto">
//       <section className="bg-slate-200 min-h-screen flex items-center justify-center">
//         {/* LOGIN CONTAINER: */}
//         <div className="bg-white flex flex-col md:flex-row rounded-2xl shadow-xl max-w-3xl p-5">
//           {/* FORM */}
//           <div className="w-full md:w-1/2 px-4 md:px-8 lg:px-16 order-2 md:order-1">
//             <h2 className="font-bold text-2xl text-center">Login</h2>
//             {/* <p className="text-sm mt-4">
//               If you are already a member, easily log in
//             </p> */}

//             <form className="flex flex-col">
//               <label className="mt-4 text-gray-600 font-semibold" htmlFor="email">
//                 Email
//               </label>
//               <input
//                 className="p-2 rounded-xl border"
//                 id="email"
//                 type="text"
//                 placeholder="Enter Email"
//                 value={username}
//                 onChange={(e) => {
//                   e.preventDefault();
//                   setUsername(e.target.value);
//                 }}
//               />

//               <label htmlFor="password" className="mt-4 text-gray-600 font-semibold">
//                 Password
//               </label>
//               <input
//                 className="p-2 rounded-xl border"
//                 id="password"
//                 type="password"
//                 placeholder="Enter Password"
//                 value={password}
//                 onChange={(e) => {
//                   e.preventDefault();
//                   setPassword(e.target.value);
//                 }}
//               />
//               {/* <a href="/changepass" className="text-blue-600 hover:underline">
//             Change Password
//           </a> */}

//               {/* <Link to="/forgot-password" className="mt-2 text-gray-600 font-semibold ml-auto">
//                 Forget Password
//               </Link> */}

//                {/* Forgot Password link */}
//                <button type="button" onClick={toggleForgotPassword} className="mt-2 text-gray-600 font-semibold">
//                 Forgot Password?
//               </button>


//               <button
//                 className="bg-blue-600 rounded-xl w-full md:w-1/2 text-white py-2 m-auto mt-4 hover:scale-105 duration-300 hover:bg-blue-600 font-bold"
//                 type="submit"
//                 onClick={(e) => {
//                   e.preventDefault();
//                   handleSubmit();
//                 }}
//               >
//                 {loading ? (
//                   <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
//                 ) : (
//                   "Login"
//                 )}
//               </button>
//             </form>

//             <div>
//               <p className="text-center mt-4">OR</p>
//             </div>
//             <div>
//               {/* <p className="text-center">
//                 Click Here to
//                 <button className="bg-violet-600 rounded-xl text-white py-2 mt-1 ml-2 hover:scale-105 duration-300 hover:bg-violet-600 font-bold">
                  

//                   <Link to="/signup">Signup</Link>
//                 </button>
//               </p> */}

//               <h4>
//                 Don't have an account?
//                 <Link to="/signup" className=" ml-2 font-medium text-blue-400">
//                   Signup
//                 </Link>
//               </h4>
//             </div>
//           </div>

//           {/* IMAGE */}
//           <div className="w-full md:w-1/2 p-5 m-auto order-1 md:order-2">
//             <img className="rounded-2xl w-full h-auto" src={bg1} alt="Login" />
//           </div>
//         </div>
//       </section>


//       {/* Forgot Password Modal */}
//       {isForgotPasswordOpen && (
//         <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg w-96">
//             <h2 className="text-2xl font-semibold text-center mb-6">Reset Your Password</h2>
//             <ForgotPassword toggle={toggleForgotPassword} onResetSuccess={toggleResetPassword} />
//           </div>
//         </div>
//       )}

//       {/* Reset Password Modal */}
//       {isResetPasswordOpen && (
//         <div className="fixed inset-0 bg-gray-500 bg-opacity-50 flex justify-center items-center z-50">
//           <div className="bg-white p-6 rounded-lg shadow-lg w-96">
//             <h2 className="text-2xl font-semibold text-center mb-6">Create a New Password</h2>
//             <ResetPassword toggle={toggleResetPassword} uid={uid} token={token} />
//           </div>
//         </div>
//       )}


//     </div>
//   );
// }
