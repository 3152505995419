import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import logo from "../images/logo.png";
import profile from "../images/profile.png";
import add from "../images/add.png";
import apply from "../images/apply.png";
import select from "../images/select.png";
import logout from "../images/logout.png";
import config from "../functions/config";

export default function Userprofileadd() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const { id } = useParams();

  const [resume, setResume] = useState("");
  const [skills, setSkills] = useState("");
  const [experience, setExperience] = useState("");
  const [education, setEducation] = useState("");

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  const handleSubmit = async (id) => {
    const data = {
      resume: resume,
      skills: skills,
      experience: experience,
      education: education,
    };
    try {
      // const response = await axios.post(
      //   `${baseUrl}api/v1/ex1/userprofile/add`,
      //   data,

      const response = await axios.post(
        `${config.baseUrl}api/v1/ex1/userprofile/add`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        console.log(response);
      } else {
        console.log("error 1");
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div>
      <div className="">
        <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-60 bg-violet-600`}
        >
          <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to="/jobscopy">Jobs</Link>
            </li>
            <li>
              <Link to="/jobuserapply">Applied</Link>
            </li>
            {/* <li>
              <Link to="/jobuserselected">Jobs Selected</Link>
            </li> */}
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex min-h-screen bg-slate-200">
        <nav>
          <div className="hidden md:block sm:w-[200px] md:w-[280px] h-lvh bg-violet-600">
            <div className="flex items-center p-5">
              {/* <img src={logo} width={50} height={50} /> */}
              {/* <h1 className="ml-3">GOOD JOBS</h1> */}
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={profile} width={30} height={30} /> */}

                {/* <a href="/userprofile" className="ml-5">
                  Profile
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>
              {/* <li className='flex items-center mt-7 px-8'>
                            <img src={profile}  width={30} height={30} />
                            <a href='/myjobs' className='ml-5'>Jobs</a>
                        </li> */}
              <li className="flex items-center mt-7 px-8">
                {/* <img src={add} width={30} height={30} /> */}

                {/* <a href="/jobs/add" className="ml-5">
                  Add Jobs
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobscopy" className="ml-5 font-bold text-white">
                  Jobs
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={apply} width={30} height={30} /> */}

                {/* <a href="/jobapplied" className="ml-5">
                  Jobs Applied
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobuserapply" className="ml-5 font-bold text-white">
                  Applied
                </Link>
              </li>
              {/* <li className="flex items-center mt-7 px-8">
                

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link to="/jobuserselect" className="ml-5 font-bold text-white">
                  Jobs Selected
                </Link>
              </li> */}
              <li className="flex items-center mt-7 px-8">
                {/* <img src={logout} width={30} height={30} /> */}

                {/* <a href="/" className="ml-5">
                  Logout
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        <div className="max-w-3xl m-auto p-6 bg-white rounded-lg shadow-lg h-full">
          <h1 className="text-2xl font-semibold text-center mb-6 text-violet-600 ">
            Add Userprofile
          </h1>
          <form className="space-y-6">
            <div className="space-y-4">
              <div>
                <label
                  htmlFor="resume"
                  className="block text-sm font-medium text-gray-700"
                >
                  Resume:
                </label>
                <input
                  id="resume"
                  type="file"
                  onChange={(e) => setResume(e.target.files[0])}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="skill"
                  className="block text-sm font-medium text-gray-700"
                >
                  Skills:
                </label>
                <input
                  id="skills"
                  type="text"
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="experience"
                  className="block text-sm font-medium text-gray-700"
                >
                  Experience (in years):
                </label>
                <input
                  id="experience"
                  type="number"
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="education"
                  className="block text-sm font-medium text-gray-700"
                >
                  Education:
                </label>
                <input
                  id="education"
                  type="text"
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                  className="mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="mt-4 w-full px-6 py-3 bg-violet-600 text-white font-semibold rounded-md hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(id);
                }}
              >
                Submit
              </button>
            </div>
            {/* <div>
              <button className="mt-4 w-full px-6 py-3 bg-violet-600 text-white font-semibold rounded-md hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-blue-500"><Link to="/userprofile">UserProfiles</Link></button>
            </div> */}
          </form>
        </div>
      </div>
    </div>
  );
}
