import { Link } from "react-router-dom";
import axios from "axios";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import config from "../functions/config";

export default function Jobadd() {
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const [salary, setSalary] = useState("");
  const [location, setLocation] = useState("");
  const [job_type, setJob_type] = useState("");
  const [description, setDescription] = useState("");
  const [department, setDepartment] = useState("");
  const [shift, setShift] = useState("");
  const [required_skills, setRequired_skills] = useState("");
  const [experience_required, setExperience_required] = useState("");
  const navigate = useNavigate("");

  const [loading, setLoading] = useState(false);

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  const shiftOptions = ["Day Shift", "Night Shift"];

  const experienceOptions = [
    { value: "", label: "Select Experience" },
    { value: "Freshers", label: "Freshers" },
    { value: "0", label: "0 Years" },
    { value: "1", label: "1 Year" },
    { value: "2", label: "2 Years" },
  ];

  const jobTypeOptions = [
    { value: "", label: "Select Job Type" },
    { value: "Full-time", label: "Full-time" },
    { value: "Part-time", label: "Part-time" },
    { value: "Temporary", label: "Temporary" },
  ];

  const departmentOptions = [
    { value: "", label: "Select Department" },
    { value: "IT", label: "IT" },
    { value: "Banking", label: "Banking" },
    { value: "Accounting", label: "Accounting" },
    { value: "Nursing", label: "Nursing" },
  ];

  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const handleSubmit = async (id) => {
    setLoading(true)
    const data = {
      title: title,
      company: company,
      salary: salary,
      location: location,
      description: description,
      job_type: job_type,
      department: department,
      shift: shift,
      required_skills: required_skills,
      experience_required: experience_required,
    };
    try {
      // const response = await axios.post(`${baseUrl}api/v1/ex1/jobs/add`, data, {

      const response = await axios.post(
        `${config.baseUrl}api/v1/ex1/jobs/add`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (response.data.status === 200) {
        console.log(response);
        setLoading(false)
        toast.success("Job Added Successfully", {
          autoclose: 1000,
          position: "top-right",
          onClose: () => {
            navigate("/employerd");
          },
        });
      } else {
        console.log("error 1");
        toast.error("Error", {
          autoclose: 1000,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("error");
      toast.error("Error", {
        autoclose: 1000,
        position: "top-right",
      });
    }
  };

  return (
    <div className="">
     
        <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div>

        {/* <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-62 bg-violet-400`}
          >
          <ul className="space-y-2 px-4 py-4 text-violet-00 text-lg text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to="/employerd">Jobs</Link>
            </li>
            <li>
              <Link to="/jobs/add">Add Jobs</Link>
            </li>
            <li>
              <Link to="/jobapplied">Applications</Link>
            </li>
            <li>
              <Link to="/jobselected">Applications Selected</Link>
            </li>
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div> */}
      

      <div className="flex bg-slate-100 ">
        <nav className="">
          <div className="hidden md:block w-56 md:w-[280px]  bg-violet-600 fixed top-0 left-0 bottom-0 ">
            <div className="flex items-center p-5">
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/employerd" className="ml-5 font-bold text-white">
                  Jobs
                </Link>
              </li>

              <li className="flex items-center mt-7 px-8">
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                  />
                </svg>

                <Link to="/jobs/add" className="ml-5 font-bold text-white">
                  Add Jobs
                </Link>
              </li>

              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobapplied" className="ml-5 font-bold text-white">
                  Applications
                </Link>
              </li>
              {/* <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link to="/jobselected" className="ml-5 font-bold text-white">
                  Applications Selected
                </Link>
              </li> */}
              <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>
        
          
        <div className="flex justify-center mt-5 mb-5 mx-auto w-full">

        
        
          <form
            encType="multipart/form-data"
            // className="bg-white p-8 rounded-lg shadow-lg sm:w-[300px] ml-3 mr-3 md:w-[480px] lg:w-[600px] transition-transform transform hover:scale-105"
            className="bg-white p-8 rounded-lg shadow-lg w-full sm:w-[500px] ml-3 mr-3 md:ml-48 md:w-[380px] lg:w-[600px] transition-transform transform hover:scale-105"
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >
            <h2 className="text-2xl font-bold text-center text-violet-600 mb-6">
              Add Job
            </h2>

            <div className="mb-6">
              <label
                htmlFor="title"
                className="block text-sm font-medium text-gray-800"
              >
                Title :
              </label>
              <input
                type="text"
                id="title"
                placeholder="Enter the job title"
                value={title}
                onChange={(e) => {
                  e.preventDefault();
                  setTitle(e.target.value);
                }}
                required
                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
              />
            </div>

            <div className="mb-6">
              <label
                htmlFor="Company"
                className="block text-sm font-medium text-gray-800"
              >
                Company :
              </label>
              <input
                type="text"
                id="company"
                placeholder="Enter the company name"
                value={company}
                onChange={(e) => {
                  e.preventDefault();
                  setCompany(e.target.value);
                }}
                required
                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="description"
                className="block text-sm font-medium text-gray-800"
              >
                Description :
              </label>
              <input
                type="text"
                id="description"
                placeholder="Enter the description"
                value={description}
                onChange={(e) => {
                  e.preventDefault();
                  setDescription(e.target.value);
                }}
                required
                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-800">
                Job Type
              </label>
              <select
                value={job_type}
                onChange={(e) => setJob_type(e.target.value)}
                className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
              >
                {jobTypeOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="salary"
                className="block text-sm font-medium text-gray-800"
              >
                Salary:
              </label>
              <input
                type="number"
                id="salary"
                placeholder="Enter the salary"
                value={salary}
                onChange={(e) => {
                  e.preventDefault();
                  setSalary(e.target.value);
                }}
                required
                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
              />
            </div>
            <div className="mb-6">
              <label
                htmlFor="location"
                className="block text-sm font-medium text-gray-700 "
              >
                Location:
              </label>
              <input
                id="location"
                type="text"
                placeholder="Enter the location"
                value={location}
                onChange={(e) => {
                  e.preventDefault();
                  setLocation(e.target.value);
                }}
                className="mt-1 block w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500 bg-gray-50"
              />
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-800">
                Department
              </label>
              <select
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
                className="w-full border-2 border-gray-300 p-2 rounded mt-2 custom-dropdown"
              >
                {departmentOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-800">
                Shift
              </label>
              <select
                value={shift}
                onChange={(e) => setShift(e.target.value)}
                className="w-full border-2 border-gray-300 p-2 rounded mt-2"
              >
                <option value="">Select Shift</option>
                {shiftOptions.map((option, index) => (
                  <option key={index} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label className="block text-sm font-medium text-gray-800">
                Experience Required
              </label>
              <select
                value={experience_required}
                onChange={(e) => setExperience_required(e.target.value)}
                className="w-full border-2 border-gray-300 p-2 rounded mt-2 overflow-y-auto"
              >
                {experienceOptions.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-6">
              <label
                htmlFor="required_skills"
                className="block text-sm font-medium text-gray-800"
              >
                Required_Skills :
              </label>
              <input
                type="text"
                id="required_skills"
                placeholder="Enter the required skills"
                value={required_skills}
                onChange={(e) => {
                  e.preventDefault();
                  setRequired_skills(e.target.value);
                }}
                required
                className="mt-1 block w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-purple-500 transition duration-150 ease-in-out"
              />
            </div>

            <button
              type="submit"
              className="w-full bg-violet-600 text-white font-semibold py-3 rounded-lg hover:bg-violet-500 transition duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-purple-300"
            >
              {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Submit"
                )}
              
            </button>
          </form>
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-62 bg-violet-600`}
          >
          <ul className="space-y-2 px-4 py-4 text-[16px] text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to="/employerd">Jobs</Link>
            </li>
            <li>
              <Link to="/jobs/add">Add Jobs</Link>
            </li>
            <li>
              <Link to="/jobapplied">Applications</Link>
            </li>
            {/* <li>
              <Link to="/jobselected">Selected</Link>
            </li> */}
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>

      </div>
    </div>
  );
}
