import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../functions/config";
import fg1 from "../images/fg1.jpeg";

export default function Forgotpassword() {
  const baseUrl = localStorage.getItem("baseUrl");

  const navigate = useNavigate();

  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [uid, setUid] = useState({});
  const [token, setToken] = useState({});

  const handleSubmit = async (e) => {
    const data = {
      email: email,
    };

    try {
      // const response = await axios.post(`${baseUrl}api/v1/auth/password-reset`,data,{

      const response = await axios.post(
        `${config.baseUrl}api/v1/auth/password-reset`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      if (response) {
        console.log(response);
        setMessage(response.data.message);
        // setUid(response.data.uid)
        // setToken(response.data.token)
        const { uid, token } = response.data;
        localStorage.setItem("email", email);

        navigate(`/reset-password/${uid}/${token}`);
      } else {
        console.log("error 1");
      }
    } catch (err) {
      // If error, display error message
      if (err.response && err.response.data) {
        setError(err.response.data.error || "An error occurred");
      } else {
        setError("Something went wrong. Please try again later.");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="min-h-screen flex justify-center items-center bg-gray-50">
      <div className="container mx-auto p-4">
        <div className="flex flex-wrap justify-center items-center bg-white shadow-xl rounded-lg overflow-hidden max-w-4xl mx-auto">
          {/* Form Section */}
          <div className="w-full md:w-1/2 p-6 order-2 md:order-1">
            <h2 className="text-2xl font-semibold text-center mb-6">
              Password Reset
            </h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(e);
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="email"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email Address
                </label>
                <input
                  id="email"
                  type="email"
                  value={email}
                  onChange={(e) => {
                    e.preventDefault();
                    setEmail(e.target.value);
                  }}
                  required
                  className="mt-1 px-4 py-2 w-full border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500"
                />
              </div>
              <div className="mb-4">
                <button
                  type="submit"
                  disabled={loading}
                  className="w-full py-2 px-4 bg-violet-600 text-white font-semibold rounded-lg disabled:opacity-50 hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  {loading ? "Sending..." : "Reset Password"}
                </button>
              </div>
            </form>

            {message && (
              <div className="text-green-600 mt-4 text-center">{message}</div>
            )}
            {error && (
              <div className="text-red-600 mt-4 text-center">{error}</div>
            )}
          </div>

          {/* Image Section */}
          <div className="w-full md:w-1/2 p-6 order-1 md:order-2">
            <img
              src={fg1}
              alt="Password Reset"
              className="w-full h-auto rounded-lg object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
