import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import config from "../functions/config";

export default function Apply() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [loading, setLoading] = useState(false);

  const { id } = useParams();

  const [user, setUser] = useState([]);
  const [array, setArray] = useState([]);
  const [data, setData] = useState([]);
  const [resume, setResume] = useState("");
  const [skills, setSkills] = useState("");
  const [experience, setExperience] = useState("");
  const [education, setEducation] = useState("");
  const [job_type, setJob_type] = useState("");
  const [title, setTitle] = useState("");
  const [company, setCompany] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchdata() {
      try {
        

        const response = await axios.get(
          `${config.baseUrl}api/v1/ex1/job/${id}`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Token ${token}`,
            },
          }
        );

        const res1 = await axios.get(
          `${config.baseUrl}api/v1/ex1/userprofile`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              "Authorization": `Token ${token}`,
            },
          }
        );

        

        const res3 = await axios.get(`${config.baseUrl}api/v1/ex1/jobapp`, {
          headers: {
            "Content-Type": "multipart/form-data",
            "Authorization": `Token ${token}`,
          },
        });

        if (response.data.status === 200) {
          console.log(response);
          setArray(response.data.data);
          setTitle(response.data.data.title);
          setCompany(response.data.data.company);
          setJob_type(response.data.data.job_type);
        } else {
          console.log("error 1");
        }

        if (res1.data.status === 200) {
          console.log(res1);
          setData(res1.data.data);
          setUser(res1.data.data);

          setSkills(res1.data.data.skills);
          setEducation(res1.data.data.education);
          setExperience(res1.data.data.experience);
        } else {
          console.log("error 2");
        }

        if (res3.data.status === 200) {
          console.log(res3);
        } else {
          console.log("error 2");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token]);

  const handleSubmit = async (id, resume) => {
    if (!token) {
      // User is not logged in, redirect to login page
      toast.error("You need to be logged in to apply for a job", {
        autoclose: 1100,
        position: "top-right",
      });
      navigate("/login", {
        state: { from: `/apply/${id}` }, // Save the current page in state to redirect after login
      });
      return;
    }

    setLoading(true);

    const data = {
      job: id,
      resume: resume,
    };
    try {
      // const res2 = await axios.post(`${baseUrl}api/v1/ex1/jobapp/add`, data, {

      const res2 = await axios.post(
        `${config.baseUrl}api/v1/ex1/jobapp/add`,
        data,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res2.data.status === 200) {
        console.log(res2);

        const data = {
          is_applied: true,
        };
        try {
          // const res3 = await axios.put(
          //   `${baseUrl}api/v1/ex1/jobapp/apply/${id}`,
          //   data,

          const res3 = await axios.put(
            `${config.baseUrl}api/v1/ex1/jobapp/apply/${id}`,
            data,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Token ${token}`,
              },
            }
          );
          if (res3.data.status === 200) {
            console.log(res3);
            setLoading(false);
            toast.success("Job Applied Successfully", {
              autoclose: 1100,
              position: "top-right",
              onClose: () => {
                navigate("/jobscopy");
              },
            });
          } else {
            console.log("error 3");
            toast.error("Error", {
              autoclose: 1100,
              position: "top-right",
            });
          }
        } catch (error) {
          console.log("error");
        }
      } else {
        console.log("error 2");
      }
    } catch (error) {
      console.log("error");
    }
  };

  return (
    <div>
      <div className="flex bg-slate-200 min-h-screen">
        {/* <nav>
          <div className="sm:w-[200px] md:w-[280px] h-full bg-violet-600">
            <div className="flex items-center p-5">
              
              <h5 className="text-3xl font-bold pr-5 text-white">Jobstreet</h5>
            </div>
            <ul>
              <li className="flex items-center mt-7 px-8">
               
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>
              
              <li className="flex items-center mt-7 px-8">
               
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobscopy" className="ml-5 font-bold text-white">
                  Jobs
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link to="/jobuserapply" className="ml-5 font-bold text-white">
                  Jobs Applied
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link
                  to="/jobuserselected"
                  className="ml-5 font-bold text-white"
                >
                  Jobs Selected
                </Link>
              </li>
              <li className="flex items-center mt-7 px-8">
                

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav> */}

        <div className="max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-xl">
          <h1 className="text-2xl font-semibold text-center text-violet-600 mb-6">
            Job Application
          </h1>
          <form className="space-y-6">
            <div className="grid grid-cols-2 gap-4">
              <div className="col-span-2">
                <label
                  htmlFor="company"
                  className="block text-sm font-medium text-gray-700"
                >
                  Company:
                </label>
                <input
                  id="company"
                  type="text"
                  value={array.company}
                  className="font-semibold mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>

              <div className="col-span-2">
                <label
                  htmlFor="title"
                  className="block text-sm font-medium text-gray-700"
                >
                  Post:
                </label>
                <input
                  id="title"
                  type="text"
                  value={array.title}
                  className="font-semibold mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="job_type"
                className="block text-sm font-medium text-gray-700"
              >
                Job Type:
              </label>
              <input
                id="job_type"
                type="text"
                value={array.job_type}
                className="font-semibold mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            </div>

            <div className="space-y-4">
              <div>
                <span className="block text-sm font-medium text-gray-700">
                  Current Resume
                </span>

                {/* <a href={user.resume}>Resume</a> */}

                <a
                  href={`${user.resume}`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="text-blue-500"
                >
                  <span className="font-semibold">Resume</span>
                </a>
              </div>
              <div>
                <label
                  htmlFor="resume"
                  className="block text-sm font-medium text-gray-700"
                >
                  Resume:
                </label>
                <input
                  id="resume"
                  type="file"
                  onChange={(e) => setResume(e.target.files[0])}
                  className="mt-1 block w-full md:px-4 md:py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="skills"
                  className="block text-sm font-medium text-gray-700"
                >
                  Skills:
                </label>
                <input
                  id="skills"
                  type="text"
                  // value={data.skills}
                  value={skills}
                  onChange={(e) => setSkills(e.target.value)}
                  className="font-semibold mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="experience"
                  className="block text-sm font-medium text-gray-700"
                >
                  Experience (in years):
                </label>
                <input
                  id="experience"
                  type="number"
                  // value={data.experience}
                  value={experience}
                  onChange={(e) => setExperience(e.target.value)}
                  className="font-semibold mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>

              <div>
                <label
                  htmlFor="education"
                  className="block text-sm font-medium text-gray-700"
                >
                  Education:
                </label>
                <input
                  id="education"
                  type="text"
                  // value={data.education}
                  value={education}
                  onChange={(e) => setEducation(e.target.value)}
                  className="font-semibold mt-1 block w-full px-4 py-2 border border-gray-300 rounded-md focus:ring-2 focus:ring-blue-500 focus:outline-none"
                />
              </div>
            </div>

            <div className="text-center">
              <button
                type="submit"
                className="mt-4 w-full px-6 py-3 bg-violet-600 text-white font-semibold rounded-md hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
                onClick={(e) => {
                  e.preventDefault();
                  handleSubmit(id, resume);
                }}
              >
                {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Submit"
                )}
                
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
