import { Link } from "react-router-dom";
import axios from "axios";
import React, { useEffect, useState } from "react";
import logo from "../images/logo.png";
import profile from "../images/profile.png";
import add from "../images/add.png";
import apply from "../images/apply.png";
import select from "../images/select.png";
import logout from "../images/logout.png";
import { useParams } from "react-router-dom";
import config from "../functions/config";

export default function Userprofiles() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const [data, setData] = useState({});

  const { id } = useParams();

  useEffect(() => {
    async function fetchdata() {
      try {
        // const response = await axios.get(`${baseUrl}api/v1/ex1/userprofile`, {
        
        const response = await axios.get(`${config.baseUrl}api/v1/ex1/userprofile`, {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Token ${token}`,
          },
        });
        if (response.data.status === 200) {
          console.log(response);
          setData(response.data.data);
        } else {
          console.log("error 1");
        }
      } catch (error) {
        console.log("error");
      }
    }
    fetchdata();
  }, [baseUrl, token]);
  return (
    <div className="flex bg-slate-200 min-h-screen">
      <nav>
        <div className=" sm:w-[200px] md:w-[280px] h-full bg-violet-600">
          <div className="flex items-center p-5">
            {/* <img src={logo} width={50} height={50} /> */}
            {/* <h1 className="ml-3">GOOD JOBS</h1> */}
            <h5 className="text-3xl font-bold pr-5 text-white">Jobstreet</h5>
          </div>
          <ul>
            <li className="flex items-center mt-7 px-8">
              <img src={profile} width={30} height={30} />

              {/* <a href="/userprofile" className="ml-5">
                Profile
              </a> */}

              <Link to="/userprofile" className="ml-5 text-white">Profile</Link>

            </li>
            {/* <li className='flex items-center mt-7 px-8'>
                    <img src={profile}  width={30} height={30} />
                    <a href='/myjobs' className='ml-5'>Jobs</a>
                </li> */}
            <li className="flex items-center mt-7 px-8">
              <img src={add} width={30} height={30} />

              {/* <a href="/jobs" className="ml-5">
                Jobs
              </a> */}

              <Link to="/jobscopy" className="ml-5 text-white">Jobs</Link>

            </li>
            <li className="flex items-center mt-7 px-8">
              <img src={apply} width={30} height={30} />

              {/* <a href="/jobs" className="ml-5">
                Jobs Applied
              </a> */}

              <Link to="/jobuserapply" className="ml-5 text-white">Jobs Applied</Link>

            </li>
            <li className="flex items-center mt-7 px-8">
              <img src={select} width={30} height={30} />

              {/* <a href="/jobs" className="ml-5">
                Jobs Selected
              </a> */}

              <Link to="/jobuserselected" className="ml-5 text-white">Jobs Selected</Link>

            </li>
            <li className="flex items-center mt-7 px-8">
              <img src={logout} width={30} height={30} />

              {/* <a href="/" className="ml-5">
                Logout
              </a> */}

              <Link to="/" className="ml-5 text-white">Logout</Link>

            </li>
          </ul>
        </div>
      </nav>

      <div className="flex-1 p-6 overflow-auto mx-auto my-auto">
        <button className="bg-violet-600 text-white rounded-lg w-[80px] mb-4"><Link to="/userprofiles/add">Add Skills</Link></button>
        <div className="bg-white p-5 shadow-lg rounded-md">
          <h3 className="text-xl font-semibold mb-3">Profile</h3>
          <div className="space-y-4">
            <h4 className="text-lg">Education: {data.education}</h4>
            <h4 className="text-lg">Skills: {data.skills}</h4>
            <h4 className="text-lg">Experience: {data.experience}</h4>
            <h4 className="text-lg">
              Resume:<a href={`${data.resume}`} target="_blank" rel="noopener.noreferrer">Resume</a>
              {/* <a>{data.resume}</a> */}
            </h4>
            <button>
              {/* <a href="userprofile/update/:id">Update</a> */}

              <button className="bg-violet-600 text-white rounded-lg w-[60px]"><Link to="update/:id">Update</Link></button>

            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
