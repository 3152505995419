import { Link } from "react-router-dom";
import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import logo from "../images/logo.png";
import profile from "../images/profile.png";
import add from "../images/add.png";
import apply from "../images/apply.png";
import select from "../images/select.png";
import logout from "../images/logout.png";
import config from "../functions/config";
import cp from "../images/cp.jpg";
import cp2 from "../images/cp2.jpeg"
import cp3 from "../images/cp3.jpeg"

export default function Changepassword() {
  const baseUrl = localStorage.getItem("baseUrl");
  const token = localStorage.getItem("token");

  const user_type = localStorage.getItem("user_type");

  const [loading, setLoading] = useState(false);

  const [old_password, setOld_password] = useState("");
  const [new_password, setNew_password] = useState("");
  const [confirm_password, setConfirm_password] = useState("");
  const [error, setError] = useState("");

  const navigate = useNavigate();

  const [isMenuOpen, setIsMenuOpen] = useState(false); // Add state for mobile menu

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen); // Toggle the menu state on button click
  };

  const validatePassword = (password) => {
    const minLength = 8;
    const hasNumber = /\d/;
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/;
    const hasUppercase = /[A-Z]/;

    return (
      password.length >= minLength &&
      hasNumber.test(password) &&
      hasSpecialChar.test(password) &&
      hasUppercase.test(password)
    );
  };

  const handleSubmit = async () => {
    setLoading(true);
    if (!validatePassword(new_password)) {
      setError(
        "New password must be at least 8 characters long, include a number and a special character and an uppercase letter."
      );
      return;
    }

    if (new_password !== confirm_password) {
      setError("New password and confirm password do not match.");
      return;
    }

    setError(""); // Clear previous errors
    const data = {
      old_password,
      new_password,
    };

    try {
      // const res = await axios.put(`${baseUrl}api/v1/auth/changepass`, data, {

      const res = await axios.put(
        `${config.baseUrl}api/v1/auth/changepass`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token ${token}`,
          },
        }
      );
      if (res.data.status === 200) {
        console.log(res);
        setLoading(false);
        toast.success("Password Changed Successfully", {
          autoClose: 1100,
          position: "top-right",
          onClose: () => {
            navigate("/");
          },
        });
      } else {
        console.log("Error: Missing number and special character");
        toast.error(res.data.message, {
          autoClose: 1100,
          position: "top-right",
        });
      }
    } catch (error) {
      console.log("Error:", error);
      toast.error("Error", {
        autoClose: 1100,
        position: "top-right",
      });
    }
  };

  return (
    <div>
      <div className="">
        <div className="flex md:hidden justify-between items-center bg-violet-600 w-full p-4">
          <button
            onClick={(e) => {
              e.preventDefault();
              toggleMenu();
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="size-6 text-white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          </button>
          <h5 className="text-3xl font-bold text-white">Careerhub</h5>
        </div>

        <div
          className={`md:hidden ${
            isMenuOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300 ease-in-out absolute top-22 left-0 w-36 h-62 bg-violet-600`}
        >
          <ul className="space-y-2 px-4 py-4 text-lg text-white font-bold">
            <li>
              <Link to="/userprofile">Profile</Link>
            </li>
            <li>
              <Link to={user_type === "user" ? "/jobscopy" : "/employerd"}>
                Jobs
              </Link>
            </li>
            {user_type === "employer" && (
              <li>
                <Link to="/jobs/add">Add Jobs</Link>
              </li>
            )}
            <li>
              <Link to={user_type === "user" ? "/jobuserapply" : "/jobapplied"}>
                {user_type === "user" ? "Applied" : "Applications"}
              </Link>
            </li>
            {/* <li>
              <Link
                to={user_type === "user" ? "/jobuserselected" : "/jobselected"}
              >
                {user_type === "user"
                  ? "Jobs Selected"
                  : "Applications Selected"}
              </Link>
            </li> */}
            <li>
              <Link to="/">Logout</Link>
            </li>
          </ul>
        </div>
      </div>

      <div className="flex bg-slate-200 min-h-screen">
        <nav>
          <div className="hidden md:block sm:w-[200px] md:w-[280px] h-full bg-violet-600">
            <div className="flex items-center p-5">
              {/* <img src={logo} width={50} height={50} /> */}
              {/* <h1 className="ml-3">GOOD JOBS</h1> */}
              <h5 className="text-3xl font-bold pr-5 text-white">Careerhub</h5>
            </div>

            <ul>
              <li className="flex items-center mt-7 px-8">
                {/* <img src={profile} width={30} height={30} /> */}
                {/* <a href="/userprofile" className="ml-5">
                  Profile
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-8 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M17.982 18.725A7.488 7.488 0 0 0 12 15.75a7.488 7.488 0 0 0-5.982 2.975m11.963 0a9 9 0 1 0-11.963 0m11.963 0A8.966 8.966 0 0 1 12 21a8.966 8.966 0 0 1-5.982-2.275M15 9.75a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                  />
                </svg>

                <Link to="/userprofile" className="ml-5 font-bold text-white">
                  Profile
                </Link>
              </li>

              {/* <li className='flex items-center mt-7 px-8'>
                            <img src={profile}  width={30} height={30} />
                            <a href='/myjobs' className='ml-5'>Jobs</a>
                        </li> */}

              <li className="flex items-center mt-7 px-8">
                {/* <img src={add} width={30} height={30} /> */}
                {/* <a href="" className="ml-5">
                  Jobs
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link
                  to={user_type === "user" ? "/jobscopy" : "/employerd"}
                  className="ml-5 font-bold text-white"
                >
                  Jobs
                </Link>
              </li>

              {user_type === "employer" && (
                <li className="flex items-center mt-7 px-8">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    className="size-7 text-white"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z"
                    />
                  </svg>

                  <Link to="/jobs/add" className="ml-5 font-bold text-white">
                    Add Jobs
                  </Link>
                </li>
              )}

              <li className="flex items-center mt-7 px-8">
                {/* <img src={apply} width={30} height={30} /> */}
                {/* <a href="/jobs" className="ml-5">
                  Jobs Applied
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-6 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 0 0 2.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 0 0-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25ZM6.75 12h.008v.008H6.75V12Zm0 3h.008v.008H6.75V15Zm0 3h.008v.008H6.75V18Z"
                  />
                </svg>

                <Link
                  to={user_type === "user" ? "/jobuserapply" : "/jobapplied"}
                  className="ml-5 font-bold text-white"
                >
                  {user_type === "user" ? "Applied" : "Applications"}
                </Link>
              </li>

              {/* <li className="flex items-center mt-7 px-8">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M11.35 3.836c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 0 0 .75-.75 2.25 2.25 0 0 0-.1-.664m-5.8 0A2.251 2.251 0 0 1 13.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m8.9-4.414c.376.023.75.05 1.124.08 1.131.094 1.976 1.057 1.976 2.192V16.5A2.25 2.25 0 0 1 18 18.75h-2.25m-7.5-10.5H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V18.75m-7.5-10.5h6.375c.621 0 1.125.504 1.125 1.125v9.375m-8.25-3 1.5 1.5 3-3.75"
                  />
                </svg>

                <Link
                  to={
                    user_type === "user" ? "/jobuserselected" : "/jobselected"
                  }
                  className="ml-5 font-bold text-white"
                >
                  {user_type === "user"
                    ? "Jobs Selected"
                    : "Applications Selected"}
                </Link>
              </li> */}
              <li className="flex items-center mt-7 px-8">
                {/* <img src={logout} width={30} height={30} /> */}

                {/* <a href="/" className="ml-5">
                  Logout
                </a> */}

                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="size-7 text-white"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M15.75 9V5.25A2.25 2.25 0 0 0 13.5 3h-6a2.25 2.25 0 0 0-2.25 2.25v13.5A2.25 2.25 0 0 0 7.5 21h6a2.25 2.25 0 0 0 2.25-2.25V15M12 9l-3 3m0 0 3 3m-3-3h12.75"
                  />
                </svg>

                <Link to="/" className="ml-5 font-bold text-white">
                  Logout
                </Link>
              </li>
            </ul>
          </div>
        </nav>

        {/* <div className="flex items-center justify-center mx-auto min-h-screen ">
          <div className="bg-white p-6 rounded-lg shadow-md w-full max-w-lg ml-2 mr-3">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Change Password
            </h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="old_password"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Old Password:
                </label>
                <input
                  type="password"
                  id="old_password"
                  value={old_password}
                  onChange={(e) => {
                    setOld_password(e.target.value);
                  }}
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="new_password"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  New Password:
                </label>
                <input
                  type="password"
                  id="new_password"
                  value={new_password}
                  onChange={(e) => {
                    setNew_password(e.target.value);
                  }}
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                />
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirm_password"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Confirm Password:
                </label>
                <input
                  type="password"
                  id="confirm_password"
                  value={confirm_password}
                  onChange={(e) => {
                    setConfirm_password(e.target.value);
                  }}
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-violet-600 text-white font-semibold py-2 rounded-md hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
        </div> */}

        {/* <div className="flex items-center justify-center mx-auto  ">
          <div className="bg-white ">
            <h2 className="text-2xl font-semibold mb-4 text-center">
              Change Password
            </h2>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <div className="mb-4">
                <label
                  htmlFor="old_password"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Old Password:
                </label>
                <input
                  type="password"
                  id="old_password"
                  value={old_password}
                  onChange={(e) => {
                    setOld_password(e.target.value);
                  }}
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md "
                />
              </div>
              <div className="mb-4">
                <label
                  htmlFor="new_password"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  New Password:
                </label>
                <input
                  type="password"
                  id="new_password"
                  value={new_password}
                  onChange={(e) => {
                    setNew_password(e.target.value);
                  }}
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                />
                {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
              </div>
              <div className="mb-4">
                <label
                  htmlFor="confirm_password"
                  className="block text-sm font-medium text-gray-700 mb-1"
                >
                  Confirm Password:
                </label>
                <input
                  type="password"
                  id="confirm_password"
                  value={confirm_password}
                  onChange={(e) => {
                    setConfirm_password(e.target.value);
                  }}
                  required
                  className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
                />
              </div>
              <button
                type="submit"
                className="w-full bg-violet-600 text-white font-semibold py-2 rounded-md hover:bg-violet-600 focus:outline-none focus:ring-2 focus:ring-blue-300"
              >
                {loading ? (
                  <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
                ) : (
                  "Submit"
                )}
              </button>
            </form>
          </div>
          <div>
            <img src={cp} />
          </div>
        </div> */}


<div className="flex items-center justify-center min-h-screen mx-auto">
  <div className="flex bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-4xl mx-4">
    {/* Left side - Form */}
    <div className="w-full sm:w-1/2 px-8 py-6">
      <h2 className="text-2xl font-semibold mb-4 text-center text-gray-800">Change Password</h2>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleSubmit();
        }}
      >
        <div className="mb-4">
          <label
            htmlFor="old_password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Old Password:
          </label>
          <input
            type="password"
            id="old_password"
            value={old_password}
            onChange={(e) => {
              setOld_password(e.target.value);
            }}
            required
            className="block w-full px-3 py-2 border border-gray-300 rounded-md"
          />
        </div>
        <div className="mb-4">
          <label
            htmlFor="new_password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            New Password:
          </label>
          <input
            type="password"
            id="new_password"
            value={new_password}
            onChange={(e) => {
              setNew_password(e.target.value);
            }}
            required
            className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
          {error && <p className="text-red-500 text-sm mt-2">{error}</p>}
        </div>
        <div className="mb-4">
          <label
            htmlFor="confirm_password"
            className="block text-sm font-medium text-gray-700 mb-1"
          >
            Confirm Password:
          </label>
          <input
            type="password"
            id="confirm_password"
            value={confirm_password}
            onChange={(e) => {
              setConfirm_password(e.target.value);
            }}
            required
            className="block w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-300"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-violet-600 text-white font-semibold py-2 rounded-md hover:bg-violet-700 focus:outline-none focus:ring-2 focus:ring-blue-300"
        >
          {loading ? (
            <span className="block w-[16px] h-[16px] border-2 mt-[4px] border-b-0 mb-[4px] mx-auto border-white rounded-full animate-spin"></span>
          ) : (
            "Submit"
          )}
        </button>
      </form>
    </div>

    {/* Right side - Image */}
    <div className="hidden sm:block w-1/2">
      <img src={cp3} alt="Password Change" className="w-full h-full object-cover" />
    </div>
  </div>
</div>




      </div>
    </div>
  );
}
